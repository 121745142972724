import React, {  useEffect, useState } from 'react';
import TopNavigation from './topNavigation';
import SideNavigation from './sideNavigation';
import Footer from './AdminFooter';
import './index.css';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon, MDBBtn, MDBInput, MDBContainer } from "mdbreact";
import firebaseall from '../../firebaseConfig';

 const  Contact = () => {  
   const [key , setKey] = useState([]);
   const [value , setValue] = useState([]);


   useEffect(()=>{

    firebaseall.database().ref('contact-home').on('value',(snap)=>{
      // console.log( Object.keys(snap.val()))
          setKey(Object.keys(snap.val()))
      // console.log( Object.values(snap.val()))
          setValue(Object.values(snap.val()))
    },(e)=>{alert(e)})

   },[])
    
      
    return (
        <div className="flexible-content">
          <TopNavigation />
          <SideNavigation />
          <MDBContainer id="content">
          <MDBContainer>
          <p className="h5 text-center mb-4 mt-5">Home Contact</p>
      <section className="contact-section my-5">
        
       {
         value.map((d , i)=>{
            return ( 

              <MDBCard key={key[i]} className="mt-5">
              <MDBRow>
                <MDBCol lg="12">
                  <MDBCardBody className="form">
                    <h3 className="mt-4">
                      <MDBIcon icon="envelope" className="pr-2 amber-text" />
                      Contact form
                    </h3>
                    <MDBRow>
                      <MDBCol md="6">
                        <div className="md-form mb-0">
                          <MDBInput 
                            value={d.name}
                            type="text"
                            id="form-contact-name"
                            label="Your name"
                          />
                        </div>
                      </MDBCol>
                      <MDBCol md="6">
                        <div className="md-form mb-0">
                          <MDBInput
                           value={d.email}
                            type="text"
                            id="form-contact-email"
                            label="Your email"
                          />
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="6">
                        <div className="md-form mb-0">
                          <MDBInput
                           value={d.phone}
                            type="text"
                            id="form-contact-phone"
                            label="Your phone"
                          />
                        </div>
                      </MDBCol>
                      <MDBCol md="6">
                        <div className="md-form mb-0">
                          <MDBInput
                            value={d.company}
                            type="text"
                            id="form-contact-company"
                            label="Your company"
                          />
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="md-form mb-0">
                          <MDBInput
                            value={d.massage}
                            type="textarea"
                            id="form-contact-message"
                            label="Your message"
                          />
                          <MDBBtn rounded color="amber">
                            Close
                          </MDBBtn>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCol>
               
              </MDBRow>
            </MDBCard>
      

              
            )
         })
       }
         
     


    </section>
      </MDBContainer>
          </MDBContainer>
          <Footer />
        </div>
    );
  }

export default Contact;