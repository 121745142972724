import React, { useEffect, useState } from 'react';
import {  MDBRow, MDBCol, MDBContainer, MDBJumbotron } from 'mdbreact';
import {Card} from 'react-bootstrap'
import studentimg from '../../image/student2.png'
import Footer from '../Footer';
import Header from './StudentHead';
import '../../style/Student.css'
import firebaseall from '../../firebaseConfig'

const CardExample = () => { 
   const [phone , setPhone ] = useState('')
   const [email , setEmail ] = useState('')
   const [collageD , setCollage ] = useState('')
   const [branch , setBranch ] = useState('')
   const [name , setName ] = useState('')
  const [gender, setGender] = useState('')
  const [bio, setHobby] = useState('')
       
    useEffect(()=>{
      
      try{     
      firebaseall.auth().onAuthStateChanged((snap)=>{
        firebaseall.database().ref('users/'  + snap.uid).once('value').then((snapshot)=>{
            
          // snapshot.forEach((snaps)=>{
          //       setV({key:snaps.key,value:snaps.val()})
          //  })
          try {
            setEmail(snapshot.val().email)
            setCollage(snapshot.val().collageD)
            setBranch(snapshot.val().branch)
            setPhone(snapshot.val().number)
            setName(snapshot.val().name)
            setGender(snapshot.val().gender)
            setHobby(snapshot.val().bio)
          } catch(e){
             alert(e)
          }
        

       })
    })   
    } catch (e){
      alert(e)
    }
    },[])
  

  return (
    <div>
    <Header/>  
    <MDBJumbotron className="bannerbg">
            <h3 className='display-5 font-weight-bold mb-0 pt-md-2 text-center text-white'>
                  My Profile
                </h3>
                
          </MDBJumbotron>
    <MDBContainer className="student-bg">
      <MDBRow className="mt-5 student-bg">
         <MDBCol md="3" style={{ display:'flex', justifyContent:'center' }} className="mr-0">
                <Card style={{ width: '16rem' }}  className="remove text-center">
                <Card.Img variant="top" className='rounded-circle p-5 student-bg' src={studentimg}/>
                </Card>
         </MDBCol>
         <MDBCol md="9" style={{ display:'flex', justifyContent:'center' }} >
         <Card style={{ width: '17rem' }}  className="remove mt-5 student-bg">
                
                <Card.Body>
  <Card.Title className='text-center'>{ name }</Card.Title>
                    
                    <Card.Text className='text-left black-text' style={{ fontWeight: 'bold' }}>
                    Phone: {phone}
                    </Card.Text>      
                    <Card.Text className='text-left black-text' style={{ fontWeight: 'bold' }}>
                    Email:  { email}
                    </Card.Text>  
                    <Card.Text className='text-left black-text' style={{ fontWeight: 'bold' }}>
                    College: {collageD}
                    </Card.Text>  
                    <Card.Text className='text-left black-text' style={{ fontWeight: 'bold' }}>
                    Branch:  {branch}
                    </Card.Text>
                    <Card.Text className='text-left black-text' style={{ fontWeight: 'bold' }}>
                    Gender:  {gender}
                    </Card.Text>     
                    <Card.Text className='text-left black-text' style={{ fontWeight: 'bold' }}>
                    Hobby:  {bio}
                    </Card.Text>   
                </Card.Body>
                </Card>
         </MDBCol>
        </MDBRow>
        </MDBContainer>
         <Footer/>

    </div>
  )
}

export default CardExample;