import React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import '../style/Body.css';
import { Link } from 'react-router-dom'

const Body = () => {
  return (

    <div >
    <Jumbotron className="homebannerbg">
      <Container>
      <MDBRow>
         <MDBCol md='5' className="instibuddy-bgtext body-texts">
            <h3 className="text-left text-black font-weight-bold">Lengthy questions need smart approaches!
            </h3>
             <p className="text-black">
Well, there are various opportunities available in one's college right from cultural to sports and entrepreneurship to technical.
So, it's upto you that whether you want to figure out your field of interest through exploring all of them or try learning from the experiences of the seniors of your college.</p>
             <p className="text-black">Mentors of InstiBuddy's college buddy program, will guide you to the best of their capabilities in handling stress during this online semester.</p>
             <div className='button text-left'>
                <Button className="instibuddy-bg" as={Link} to='/student/signup'>Student Sign Up</Button>
                <Button className="instibuddy-bg" as={Link} to='/mentor/signup'>Mentor Sign Up</Button>
            </div>
         </MDBCol>      
      </MDBRow>
      </Container>
    </Jumbotron>
    </div>
  ); 
}

export default Body;
