import React   from "react";
import logo from '../../image/logo1.png'
import {  MDBNavbarNav, MDBNavItem, MDBNavLink,  MDBDropdown,
MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,  MDBIcon } from "mdbreact";
import {Navbar ,Button } from 'react-bootstrap'
import firebaseall from '../../firebaseConfig'
import { Link } from 'react-router-dom'

const StudentNavbar = ()=>  {

  const Logout = ()=>{
    firebaseall.auth().signOut();
    setTimeout(()=> window.location.reload() ,500)   
 }
 
 
  return ( 
    <div>   
            <Navbar collapseOnSelect  expand="md"  variant='light' style={{backgroundColor:'white',font:'caption'}} className="headerbg">
                <Navbar.Brand  href="/" ><img src={logo} style={{width:215,height:60,backgroundColor:'white'}} alt="logo" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className='collapsess'>
                <MDBNavbarNav right>
                  <MDBNavItem>
                    <MDBNavLink className="waves-effect waves-light black-text" to="/mentor/home">
                      <MDBIcon icon="home" className="mr-1 black-text" />Home</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink className="waves-effect waves-light black-text" to="/mentor/contacts">
                      <MDBIcon icon="envelope" className="mr-1 black-text" />Contact</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret className="black-text">
                        <MDBIcon icon="user" className="mr-1 black-text" />Profile
                      </MDBDropdownToggle>
                      <MDBDropdownMenu className="dropdown-default" right>
                      <Link to="/mentor/profile"><MDBDropdownItem tag="span">My account</MDBDropdownItem></Link>
                        <MDBDropdownItem as= {Button}  onClick={Logout}>Log out</MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavItem>
                </MDBNavbarNav>
                </Navbar.Collapse>
             </Navbar>         
          </div>
    );
  }

export default StudentNavbar;