import React, { useContext } from 'react'
import {AuthContext} from '../App'
import { Route, Redirect } from 'react-router-dom'

const MPrivateRoute = ({ component:RouteComponent ,  ...rest })=>{
     const { mentorUser } = useContext(AuthContext)
     
    return(  
          <Route {...rest} render = {(routeProps)=>
            !!mentorUser ? (  <RouteComponent {...routeProps} /> ) 
            : (<Redirect to ={'/mentor/login'}/>) 
            } /> 
         )     
      // <Route {...rest} render={(routeProps)=>  arrayedit ?  <RouteComponent {...routeProps} /> : 
      //  ( !!currentUser ? (<RouteComponent {...routeProps}/>) :
      //  (<Redirect to='/login'/>) ) } />
      //  );
    
    //     console.log({...rest})
    
    // const Path= rest.path
    // console.log(typeof Path)


};

export default MPrivateRoute;