import React from 'react'
import Body from './Body'
import Faq from  './Faq'
// import Blog from  './Bloghome'
// import Achiever from  './Achiever'
import Introduction from './Introduction'
// import Video from './Video'
// import TroubleQuestions from './TroubleQuestions'
import Footer from  './Footer'
import Header from './Header';
import 'semantic-ui-css/semantic.min.css'
const Home =()=>{
    return (
        <div>
            <Header/>
            <Body/>
            <Introduction/>
            {/* <TroubleQuestions/> */}
            {/* <Achiever/>
            <Video/> */}
            {/* <Blog/> */}
            <Faq/>
            <Footer/>
         </div>       
    )
}

export default Home