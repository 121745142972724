import React, { useState } from 'react'
import { Card, Accordion } from 'react-bootstrap'
import { MDBIcon } from 'mdbreact';



const Faq = () => {
    
 const [first , setFirst]  = useState(true)
 const [second , setSecond]  = useState(true)
 const [third , setthird]  = useState(true)
 const [fourth , setFourth]  = useState(true)
//  const [five , setFive]  = useState(true)
 const [six , setSix]  = useState(true)
 const [seven , setSeven]  = useState(true)
 const [eight , setEight]  = useState(true)
 const [nine , setNine]  = useState(true)
 const [ten , setTen]  = useState(true)
 const [eleven , setEleven]  = useState(true)

 const toggle1 = ()=>{
  
    if(first){

      setFirst(false)  
    }else{
      setFirst(true)
    }
  
 }
 const toggle2 = ()=>{
  
    if(second){

      setSecond(false)  
    }else{
      setSecond(true)
    }
  
 }
 const toggle3 = ()=>{
  
    if(third){

      setthird(false)  
    }else{
      setthird(true)
    }
  
 }
 const toggle4 = ()=>{
  
    if(fourth){

      setFourth(false)  
    }else{
      setFourth(true)
    }
  
 }
 
//  const toggle5 = ()=>{
  
//     if(five){

//       setFive(false)  
//     }else{
//       setFive(true)
//     }
  
//  }

 const toggle6 = ()=>{
  
    if(six){

      setSix(false)  
    }else{
      setSix(true)
    }
  
 }
 const toggle7 = ()=>{
  
  if(seven){

    setSeven(false)  
  }else{
    setSeven(true)
  }

}
const toggle8 = ()=>{
  
  if(eight){

    setEight(false)  
  }else{
    setEight(true)
  }

}
const toggle9 = ()=>{
  
  if(nine){

    setNine(false)  
  }else{
    setNine(true)
  }

}

const toggle11 = ()=>{
  
  if(eleven){

    setEleven(false)  
  }else{
    setEleven(true)
  }

}


  return (
    <div className='container mt-5'>
      <h3 className='text-center mb-4' style={{ fontWeight: 'bold' }}>Frequently asked questions</h3>
      <Accordion defaultActiveKey="1" className="mt-5">
        
        <Card onClick={()=>toggle1()}>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            <h6 style={{ fontWeight: "bold" }}>Who would be the mentor/buddy alloted through college buddy program ?<MDBIcon className="ml-5 orange-text float-right align-middle" 
            icon={first ? 'minus' : 'plus'} /></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>Mentor/Buddy allotted through collegeBuddy program could be any senior of the college where the student is going in.
</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card onClick={()=>toggle2()}>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            <h6 style={{ fontWeight: "bold" }}>What kind of question should i ask to my buddy?<MDBIcon className="ml-5 orange-text float-right align-middle" 
            icon={second ? 'plus' : 'minus'}/></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <b>Following are the example of queries you could ask to your respective college buddy:-</b>
            <br></br>
              1. What should be my method to handle academics in college along with extra-curriculum?<br></br>
               2. How is the culture of my college(Ragging,life at campus etc.)? <br></br>
               3. Before entering the college, what should I learn to be ahead of others?         
</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card  onClick={()=>toggle3()}>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            <h6 style={{ fontWeight: "bold" }}>How will be my mentor helpful in making decisions throughout this online semester? <MDBIcon className="ml-5 orange-text float-right align-middle" 
            icon={third ? 'plus' : 'minus'} /></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>Well, a senior plays a very important role in one's college life when it comes to  career choices etc, especially in engineering colleges.
There are various opportunities available in the reputation institutions such as IIT's, NIT's etc. But one needs to figure out their field of interest at their earliest so that they could cope up in the competition with their peers.
Seniors help juniors, especially 1st year students to manage stress along with figuring out a way so that the new ones could give their best when it comes to academics and the field of interest.</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card onClick={()=>toggle4()}>
          <Accordion.Toggle as={Card.Header} eventKey="4">
            <h6 style={{ fontWeight: "bold" }}>How could I register myself as a mentor for this collegeBuddy program ?<MDBIcon className="ml-5 orange-text float-right align-middle" 
            icon={fourth ? 'plus' : 'minus'}/></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>Step 1:- Signup on mentor portal via your college email address, or else, reach us out through contact form available on website,if your college isn't registered with us.<br></br>
Step 2:- Verify your email address<br></br>
Step 3:- Login using your email and password<br></br>
Step 4:- instiBuddy will shortly connect you to your mentees from your college within few days via call/email</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card onClick={()=>toggle6()}>
          <Accordion.Toggle as={Card.Header} eventKey="6">
            <h6 style={{ fontWeight: "bold" }}>How can instiBuddy help me through my journey in college?<MDBIcon className="ml-5 orange-text float-right align-middle" 
            icon={six ? 'plus' : 'minus'}/></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <Card.Body>instiBuddy will allot a suitable mentor who is a senior student in the college. The mentor will help you in solving your college related problems.
</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card onClick={()=>toggle7()}>
          <Accordion.Toggle as={Card.Header} eventKey="7">
            <h6 style={{ fontWeight: "bold" }}>What are steps required to follow in order to get desired mentor from respective colleges?<MDBIcon className="ml-5 orange-text float-right align-middle" 
            icon={seven ? 'plus' : 'minus'}/></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="7">
            <Card.Body>Step 1:- Signup on student/mentor portal<br></br>
                        Step 2:- Verify your email address<br></br>
                        Step 3:- Login using your email and password<br></br>
                        Step 4:- instiBuddy would connect you to a mentor from your college within few days via email/call
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card onClick={()=>toggle8()}>
          <Accordion.Toggle as={Card.Header} eventKey="8">
            <h6 style={{ fontWeight: "bold" }}>What should I do if I am not satisfied with the buddy alloted to me?<MDBIcon className="ml-5 orange-text float-right align-middle" 
            icon={eight ? 'plus' : 'minus'}/></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="8">
            <Card.Body>We'll float mentor feedback form (once in 2 weeks) in order to track the performance of mentors.
                       If you are not satisfied, you could definitely let us know your concerns through the feedback form or else you could reach us out via contact us section available on the website.</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card onClick={()=>toggle9()}>
          <Accordion.Toggle as={Card.Header} eventKey="9">
            <h6 style={{ fontWeight: "bold" }}>What are the registration charges(if any) for instiBuddy's collegeBuddy program ?<MDBIcon className="ml-5 orange-text float-right align-middle" 
            icon={nine ? 'plus' : 'minus'}/></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="9">
            <Card.Body>We are not charging any amount from student/mentor for this collegeBuddy program.</Card.Body>
          </Accordion.Collapse>
        </Card>
       
         <Card onClick={()=>toggle11()}>
          <Accordion.Toggle as={Card.Header} eventKey="11">
            <h6 style={{ fontWeight: "bold" }}>What are the advantages of registering as a mentor in collegeBuddy program?<MDBIcon className="ml-5 orange-text float-right align-middle" 
            icon={eleven ? 'plus' : 'minus'}/></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="11">
            <Card.Body>Top 3 mentor would be receiving cash prizes of 50k, 30k and 10k respectively. <br></br>
Top 10 mentor would be receiving a letter of appreciation from the advisory board including the likes of Praveen Malla (Board Director, IIT-B Alumni Association), Gaurav Gutgutia (Co-founder, Grabbngo), Aparna Rao (Professor,IIT-B), Kumar Shivashish (IPS officer and IIT-KGP Alumni) and Tejaswini Priyadarshini (XLRI Jamshedpur Alumnus)<br></br>
On successful completion, Every mentor would receive an internship letter for being part of the college buddy mentorship program.</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      
    </div>

  )
}

export default Faq;