import React, { useState,useEffect, createContext } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import Home from './component/Home';
import About from './component/About';
import College from './component/College';
import News from './component/News';
import Contact from './component/Contact';
import Popup from './component/mentor/PopupMentor'
import Team from './component/Team';
import Faq from './component/FaqPage'
import MentorTc from './component/CompanyTc'
import Test from './component/Test'
//auth section
import {BrowserRouter as Router ,  Route , Switch } from 'react-router-dom'
import firebaseall from '../src/firebaseConfig'
import SPrivateRoute from './component/SPrivateRoute'
import MPrivateRoute from './component/MPrivateRoute'
import APrivateRoute from './APrivateRoute'
import NotFound from '../src/component/NotFound'
//student section 
import Student from './component/student/Student';
import StudentContact from './component/student/StudentContact';
import StudentSignup from './component/student/StudentSignup';
import StudentLogin from './component/student/StudentLogin.js';
import StudentProfile from './component/student/StudentProfile';
import StudentTerms from './component/student/StudentTerms';
import StudentForgotPassword from './component/student/StudentForgotPassword'
//mentor section
import MentorSignup from './component/mentor/MentorSignup';
import MentorLogin from './component/mentor/MentorLogin';
import Mentor from './component/mentor/Mentor'
import MentorHead from './component/mentor/MentorHead'
import MentorBody from './component/mentor/MentorBody'
import MentorTerms from './component/mentor/MentorTerms'
import MentorContact from './component/mentor/MentorContact'
import PopupMentor from './component/mentor/PopupMentor'
import MentorProfile from './component/mentor/MentorProfile';
import MentorForgotPassword from './component/mentor/MentorForgotPassword'

//admin section 
import AdminHome from './component/mukul/home'
import AdminSideNav from './component/mukul/sideNavigation'
import AdminTopNav from './component/mukul/topNavigation'
import AdminFooter from './component/mukul/AdminFooter'
import AdminMentor from './component/mukul/AdminMentor'
import AdminStudent from './component/mukul/AdminStudent'
import StudentaContact from './component/mukul/StudentContact'
import MentoraContact from './component/mukul/MentorContact'
import IndexContact from './component/mukul/IndexContact'

//blog section
import Blog1 from './component/blog/blog1'
import Blog2 from './component/blog/blog2'
import Blog3 from './component/blog/blog3'

export const AuthContext = createContext();


const App = ()=>{
    
        const [currentUser ,setcurrentUser] =  useState(null)
        const [currentUserEmail,setCurrentUserEmail] = useState(); 
        const [email,setEmail] = useState(false); 
        const [user,setUser] = useState(false); 
 
      //  console.log(rate)
       const array = ["nitj.ac.in","iitbhu.ac.in","iith.ac.in","iitbbs.ac.in","iitb.ac.in","iitd.ac.in","iitdh.ac.in","iitgn.ac.in",
       "iitbhilai.ac.in","iitg.ac.in","iith.ac.in",
       "iiti.ac.in","iitjammu.ac.in","iitj.ac.in","iitk.ac.in","iitkgp.ac.in",
       "iitm.ac.in","iitmandi.ac.in","students.iitmandi.ac.in","iitpkd.ac.in","iitp.ac.in","iitr.ac.in","bt.iitr.ac.in",
       "iitrpr.ac.in","iittp.ac.in","ee.iitism.ac.in","iitism.ac.in","mnit.ac.in","manit.ac.in",
       "mnnit.ac.in","nita.ac.in","nitap.ac.in","nitc.ac.in","nitdelhi.ac.in","nitdgp.ac.in",
       "nitgoa.ac.in","nith.ac.in","nitjsr.ac.in","nitk.ac.in","nitkkr.ac.in","nitmanipur.ac.in",
       "nitm.ac.in","nitmz.ac.in","nitnagaland.ac.in","nitp.ac.in","nitpy.ac.in","nitrr.ac.in","nitrkl.ac.in",
       "nitsikkim.ac.in","nits.ac.in","nitsri.ac.in","nitt.edu","nitandhra.ac.in","nituk.ac.in","nitw.ac.in","svnit.ac.in","vnit.ac.in","gbu.ac.in",
      ]

        const mentorUser  = array.includes(String(currentUserEmail))

        useEffect(()=>{
             firebaseall.auth().onAuthStateChanged((user)=>{
   
                  if(user){
                     setUser(true)
                     setEmail(user.emailVerified)
                  }else{
                     setUser(false)
                  }    
                     if(user){
                  const email = user.email;
                  const domain = email.substring(email.lastIndexOf("@") +1);
                  setCurrentUserEmail(domain)
                  
                  if(mentorUser){
                     
                     return mentorUser;
                  }
                  else  {
                  return  setcurrentUser(user)
                  }  
               }  
     
          })
        },[])
   
        
    if(!email && user ){
         
         return (
               <AuthContext.Provider > 
               <Router>       
                   <Route  component={Popup} /> 
                </Router>   
                </AuthContext.Provider> 
             )
          

    }
    else{

   return(
   <AuthContext.Provider value={{ currentUser , mentorUser  }}> 
       <Router>  
        <Switch>       
         <Route  exact path='/' component={Home}/>   
         <Route  path='/about' component={About} />    
         <Route  path='/college' component={College} />    
         <Route  path='/test' component={Test} />   
         <Route  path='/faqs' component={Faq} />   
         <Route  path='/news&article' component={News} />     
          <Route  path='/contact' component={Contact} />       
          <Route  path='/team'  component={Team} />    
          <Route  path='/terms'  component={MentorTc} />    
          <SPrivateRoute  path='/student/home' component={Student} />    
          <Route  path='/student/signup' component={StudentSignup} />   
          <Route  path='/student/login'  component={StudentLogin} />   
          <SPrivateRoute  path='/student/contacts'  component={StudentContact} />    
          <SPrivateRoute  path='/student/profile'  component={StudentProfile} />    
          <Route  path='/sterms'  component={StudentTerms} />    
          <Route  path='/student/password'  component={StudentForgotPassword} />    
          <MPrivateRoute  path='/mentor/home'  component={Mentor} />    
          <Route  path='/mentor/signup' component={MentorSignup} />   
          <Route  path='/mentor/login'  component={MentorLogin} />    
          <MPrivateRoute  path='/mentor/body'  component={MentorBody} />    
          <MPrivateRoute  path='/mentor/head'  component={MentorHead} />    
          <MPrivateRoute path='/mentor/contacts' component={MentorContact} />    
          <MPrivateRoute path='/mentor/popup' component={PopupMentor} />    
          <Route path='/mterms' component={MentorTerms} />    
           <MPrivateRoute  path='/mentor/profile'  component={MentorProfile} />    
          <Route path='/mentor/password' component={MentorForgotPassword} />    
          <APrivateRoute path='/mukul/home' component={AdminHome} />    
          <APrivateRoute path='/mukul/footer' component={AdminFooter} />    
          <APrivateRoute path='/mukul/sidenav' component={AdminSideNav} />    
          <APrivateRoute path='/mukul/topnav' component={AdminTopNav} />    
          <APrivateRoute path='/mukul/mentor' component={AdminMentor} />    
          <APrivateRoute path='/mukul/student' component={AdminStudent} />    
          <APrivateRoute path='/mukul/studentcontact' component={StudentaContact} />    
          <APrivateRoute path='/mukul/mentorcontact' component={MentoraContact} />    
          <APrivateRoute path='/mukul/indexcontact' component={IndexContact} />    
          <Route path='/blog/blog1' component={Blog1} />    
          <Route path='/blog/blog2' component={Blog2} />    
          <Route path='/blog/blog3' component={Blog3} />
          <Route  path='*'> <NotFound/> </Route> 
          </Switch>     
        </Router>   
   </AuthContext.Provider>
   )
  }

}

export default App