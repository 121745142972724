import React ,{useEffect, useState} from 'react';
import { MDBDataTable, MDBContainer } from 'mdbreact';
import firebaseall from '../firebaseConfig'



const DatatablePrank = () => {
  const [studentDate , setStudentData]=  useState([])
   
   useEffect(()=>{

     firebaseall.database().ref('users').once('value',(snap)=>{
      //  console.log((snap.val()))
        if(snap){
          setStudentData(Object.values(snap?.val()))
        }
     })
   },[])
   
   
   
  const data = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Mobile',
        field: 'number',
        sort: 'asc',
        width: 200
      },
      
      {
        label: 'Joining date',
        field: 'date',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Gender',
        field: 'gender',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Location',
        field: 'location',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Branch',
        field: 'branch',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Collage',
        field: 'collageD',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Hobby',
        field: 'bio',
        sort: 'asc',
        width: 100
      }
    ],
    rows: studentDate 
  };

  return (
    <MDBContainer>
    <MDBDataTable
      striped
      bordered
      small
      data={data}
    />
    
    </MDBContainer>
  );
}

export default DatatablePrank;