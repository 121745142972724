import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyAGSrmul7f2d1JBsLeNwXc8hcys7HnCDJA",
  authDomain: "buddy-instibuddy.firebaseapp.com",
  databaseURL: "https://buddy-instibuddy.firebaseio.com",
  projectId: "buddy-instibuddy",
  storageBucket: "buddy-instibuddy.appspot.com",
  messagingSenderId: "560386715729",
  appId: "1:560386715729:web:d1d4daa468708f61d8c870",
  measurementId: "G-ZLT5JT3069"
};

  const firebaseall = firebase.initializeApp(firebaseConfig);
  const auth = firebaseall.auth();
  const db = firebaseall.database();
  export { auth ,db }
  export default firebaseall;