import React, {useEffect, useState } from 'react';
import TopNavigation from './topNavigation';
import SideNavigation from './sideNavigation';
import Footer from './AdminFooter';
import './index.css';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput} from 'mdbreact';
import firebaseall from '../../firebaseConfig';

  const StudentContact = ()=>{
    
    const [kye , setKey] = useState([])
    const [value , setValue] = useState([])
   
     useEffect(()=>{
        try {
          firebaseall.database().ref('contact-student').on('value',(snap)=>{
            if(snap){
              setValue(Object.values(snap.val()))
            }
         
             setKey(Object.keys(snap.val()))
      
          },(e)=>alert(e))
          
         }catch(e){
           alert(e)
        }

    },[])

        
   

    return (
        <div className="flexible-content">
          <TopNavigation />
          <SideNavigation />
          <MDBContainer id="content">
          <p className="h5 text-center mb-4 mt-5">Student Contact</p>
          <MDBRow className="d-flex justify-content-center">
          <MDBCol md="6">
            {
            
             value.map((d , i)=>{
               return(
                  
                <form key={kye[i]}>
                <div className="orange-text">
                  <MDBInput label="Your name" icon="user" group type="text" validate error="wrong"
                    success="right" value={d.name} required />
                  <MDBInput label="Your email" icon="envelope" group type="email" validate error="wrong"
                    success="right"  value={d.email} required/>
                  <MDBInput label="Subject" icon="tag" group type="text" validate error="wrong" 
                  success="right" value={d.subject}  required />
                  <MDBInput type="textarea" rows="2" label="Your message" icon="pencil-alt" value={d.massage}  required />
                </div>
                <div className="text-center">
                  <MDBBtn outline color="instibuddy-bg" >
                    Accept
                    <MDBIcon far icon="check-double" className="ml-1" />
                  </MDBBtn>
                </div>
              </form>

               )
             })
               
            }
           
          </MDBCol>
        </MDBRow>
          </MDBContainer>
          <Footer />
        </div>
    );
  }


export default StudentContact;