import React from 'react';
import {MDBJumbotron, MDBContainer } from 'mdbreact';
import '../style/Intro.css';
const Introduction=()=>{
           return(
                    <MDBJumbotron className="instibuddy-bg3 mt-5">
                        <MDBContainer>
                    <h3 className='display-5 mb-0 pt-md-3 text-center intro-font'>instiBuddy is a joint initiative taken by IIT Bombay students, 
                    professor and alumni that brings in well-experienced Mentors, who are students from different IITs, NITs, and other engineering 
                    colleges. You can have a face to face talk with the mentors and discuss your college-related doubts with them freely. 
                    .</h3>
                   </MDBContainer>
                   </MDBJumbotron>
               )
}
export default Introduction;