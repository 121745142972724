import React from 'react';
import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';
import Footer from './Footer';
import { MDBJumbotron  } from "mdbreact";
import Header from './Header';
import Admit from '../image/news/Admit.png'
import Covid from '../image/news/Covid.png'
import Key from '../image/news/Key.png'
import Jee from '../image/news/IIT.png'
import Chemistry from '../image/news/Chemistry.png'
import Results from '../image/news/Results.jpg'



const News = () => {
  return (
    <div>
      <Header/>
<div>
          <MDBJumbotron className="bannerbg">
            <h2 className='font-weight-bold mb-0 pt-md-3 text-center text-white'>
                  News & Articles
                </h2>
                <h5 className='display-6 mb-0 pt-md-2 text-center text-white'>
                  The daily bugle of colleges and examination
                </h5>
          </MDBJumbotron>
<div className="container">
<CardDeck>
<Card>
    <Card.Img variant="top" src={Chemistry} />
    <Card.Body>
    <a href="/news/article6">
      <Card.Title>How To Ace Chemistry in JEE</Card.Title></a>
      <Card.Text>
      If you expect good marks in this subject, you’ll have to focus on
fundamental concepts. This is a subject that requires a high amount of
information retainment.
      </Card.Text>
    </Card.Body>
    <Card.Footer>
      <small className="text-muted">26 June 2020 </small>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src={Results}/>
    <Card.Body>
    <a href="/news/article5">
      <Card.Title>  JEE ADVANCED 2020 Result</Card.Title></a>
      <Card.Text>
      The Indian Institute of Technology, Roorkee will release JEE Advanced 2020 result on 5 October 2020. 
      </Card.Text>
    </Card.Body>
    <Card.Footer>
      <small className="text-muted">23 September 2020 </small>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src={Jee}/>
    <Card.Body>
    <a href="/news/article4">
      <Card.Title>JEE Advanced 2020</Card.Title></a>
      <Card.Text>
      The JEE Advanced are being conducted by the Indian Institute of Technology (IIT) Delhi in two shifts- morning (9 am to 12 pm).The candidates had to appear in the exams with face masks, hand sanitisers.</Card.Text>
    </Card.Body>
    <Card.Footer>
      <small className="text-muted">21 September 2020 </small>
    </Card.Footer>
  </Card>
</CardDeck>
<CardDeck>
  <Card>
    <Card.Img variant="top" src={Admit} />
    <Card.Body>
    <a href="/news/article3">
      <Card.Title>JEE Advanced 2020 admit card released</Card.Title></a>
      <Card.Text>
      IIT Delhi has released the admit card for the JEE Advanced — the entrance exam for admission to undergraduate courses across IITs and IISc today. 
      </Card.Text>
    </Card.Body>
    <Card.Footer>
      <small className="text-muted">21 September 2020 </small>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src={Covid}/>
    <Card.Body>
    <a href="/news/article2">
      <Card.Title>Students courage to appear for JEE Advanced, despite of Covid-19 </Card.Title></a>
      <Card.Text>
      JEE Aspirants enter the examination centre to appear for JEE Advanced, at Ion Digital Zone, in Sector 62, on September 27, 2020 in Noida, India. 
      </Card.Text>
    </Card.Body>
    <Card.Footer>
      <small className="text-muted">27 September 2020</small>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src={Key}/>
    <Card.Body>
      <a href="/news/article1">
    <Card.Title>Unofficial Answer key of JEE Advanced Released</Card.Title></a>
      <Card.Text>
      JEE Advanced 2020 was conducted on Sunday, September 27. Several coaching institutions have released unofficial answer keys of JEE Advanced 2020. 
      </Card.Text>
    </Card.Body>
    
    <Card.Footer>
      <small className="text-muted">28 September 2020 </small>
    </Card.Footer>
  </Card>
</CardDeck>

</div>
 <Footer/>
     </div>
   </div>  
  );
}

export default News;