import React, { useEffect,useState } from 'react';
import TopNavigation from './topNavigation';
import SideNavigation from './sideNavigation';
import Footer from './AdminFooter';
import './index.css';
import AdminBody from './AdminBody'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { db } from '../../firebaseConfig'

const Home =()=>{

  const [mentor , setMentor] =  useState([])
  const [student , setStudent] =  useState([])
    
    useEffect(()=>{
      db.ref('mentor').on('value',(snaphot)=>{
        setMentor(Object?.values(snaphot?.val()).length)        
      })
      db.ref('users').on('value',(snaphot)=>{
        setStudent(Object?.values(snaphot?.val()).length)        
      })

    },[])
  

    return (
        <div >
          <TopNavigation />
          <SideNavigation />
          <MDBContainer id="content">
      <h3 className="text-center mt-5">Student and Mentor registered with us</h3>
      <MDBRow>
        <MDBCol><div> <AdminBody  /> </div></MDBCol>
        <MDBCol className="">
    <p className='h5 font-italic mt-5'>No. of student = {student} </p>
    <p className='h5 font-italic mt-5'>No. of Mentor = {mentor} </p>
        </MDBCol>
      </MDBRow>
      
      </MDBContainer>
          <Footer />
        </div>
    );
  
}

export default Home;