import React ,{useContext, useEffect, useState} from 'react';
import {  MDBRow, MDBCol, MDBContainer, MDBJumbotron } from 'mdbreact';
import {Card} from 'react-bootstrap'
import studentimg from '../../image/student2.png'
import Footer from '../Footer';
import Header from './MentorHead';
import '../../style/Student.css'
import firebaseall from '../../firebaseConfig'


const CardExample = () => {
   const [email , setEmail] = useState('')
   const [mentor , setMentor] = useState('')
   const [collegeD , setCollege] = useState('')
   const [joining , setJoining] = useState('')
   const [name , setName] = useState('')

  useEffect(()=>{
      
    try{     
    firebaseall.auth().onAuthStateChanged((snap)=>{
      firebaseall.database().ref('mentor/'  + snap.uid).once('value').then((snapshot)=>{
          
        // snapshot.forEach((snaps)=>{
        //       setV({key:snaps.key,value:snaps.val()})
        //  })
        try {
         setEmail(snapshot.val().full)
         setMentor(snapshot.val().branch)
         setJoining(snapshot.val().joinCollage)
         setCollege(snapshot.val().collageD) 
         setName(snapshot.val().name) 
        } catch(e){
           alert(e)
        }
      

     })
  })   
  } catch (e){
    alert(e)
  }
  },[])

 


  return (
    <div>
    <Header/>  
    <MDBJumbotron className="bannerbg">
            <h3 className='display-5 font-weight-bold mb-0 pt-md-2 text-center text-white'>
                  My Profile
                </h3>    
          </MDBJumbotron>
    <MDBContainer className="">
      <MDBRow className="mt-5 student-bg">
         <MDBCol md="3" style={{ display:'flex', justifyContent:'center' }} className="mr-0">
                <Card style={{ width: '16rem' }}  className="remove text-center">
                <Card.Img variant="top" className='rounded-circle p-5 student-bg' src={studentimg}/>
                </Card>
         </MDBCol>
         <MDBCol md="9">
  <h3 style={{ display:'flex', justifyContent:'center' }} className="mt-5">{name}</h3>
         <MDBRow className="mt-5 text-left">
             <MDBCol md="6">Email :{email} </MDBCol>
             <MDBCol md="6">Branch : {mentor} </MDBCol>
             
         </MDBRow>
         <MDBRow className=" text-left">
             <MDBCol md="6">College Name : {collegeD} </MDBCol>
             <MDBCol md="6">Year of joining : {joining} </MDBCol>
         </MDBRow>
         </MDBCol>
        </MDBRow>
        </MDBContainer>
         <Footer/>

    </div>
  )
}

export default CardExample;