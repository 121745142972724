import React, { useContext } from 'react'
import {AuthContext} from '../App'
import { Route, Redirect } from 'react-router-dom'

const SPrivateRoute = ({ component:RouteComponent ,  ...rest })=>{
     const { currentUser } = useContext(AuthContext)
   
    return(  
        
          <Route {...rest} render = {(routeProps)=> 
            !!currentUser ? (  <RouteComponent {...routeProps} /> ) 
            : (<Redirect to ={'/student/login'}/>) 
            } />  
      )      
      // <Route {...rest} render={(routeProps)=>  arrayedit ?  <RouteComponent {...routeProps} /> : 
      //  ( !!currentUser ? (<RouteComponent {...routeProps}/>) :
      //  (<Redirect to='/login'/>) ) } />
      //  );
    
    //     console.log({...rest})
    
    // const Path= rest.path
    // console.log(typeof Path)

};

export default SPrivateRoute;