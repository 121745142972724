import React from 'react'
import '../style/Header.css'
import logo from '../image/logo1.png'
import {Navbar ,Nav } from 'react-bootstrap'
import { Link  } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

const Header = ()=>{
  
    return( 
             <Navbar collapseOnSelect  expand="md"  variant='light' style={{backgroundColor:'white',font:'caption'}} className="headerbg">
                <Navbar.Brand rel="noopener noreferrer" href="https://instibuddy.in/" target="_blank"><img src={logo} style={{width:215,height:60,backgroundColor:'white'}} alt="logo" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav basic-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className='collapsess'>
                    <Nav >
                    <Nav.Link as={Link} to='/' className="text-black"> Home </Nav.Link>
                    <Nav.Link as={Link} to='/college'> Colleges Associated</Nav.Link>
                    <Nav.Link as={Link} to='/team'>Team </Nav.Link>
                    <Nav.Link as={Link} to='/student/home'>Student Login</Nav.Link>
                    <Nav.Link as={Link} to='/mentor/home'>Mentor Login</Nav.Link>
                    <Nav.Link as={Link} to='/contact'>Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
             </Navbar> 
    )
}
export default withRouter(Header);