import React ,{useEffect, useState} from "react";
import { Pie } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";
import { db } from '../../firebaseConfig'


const AdminBody = ({student1 , mentor1})=>  {
        
    const [mentor , setMentor] =  useState([])
    const [student , setStudent] =  useState([])
      
      useEffect(()=>{
        db.ref('mentor').on('value',(snaphot)=>{
          setMentor(Object?.values(snaphot?.val()).length)        
        })
        db.ref('users').on('value',(snaphot)=>{
          setStudent(Object?.values(snaphot?.val()).length)        
        })

      },[])
 
  

      const dataPie= {
            labels: ["Mentor", "Student"],
            datasets: [
              {
                data: [mentor, student],
                backgroundColor: [
                  "#F7464A",
                  "#46BFBD"
                ],
                hoverBackgroundColor: [
                  "#FF5A5E",
                  "#5AD3D1"
                ]
              }
            ]
          }



  
    return (
      <MDBContainer>
        <Pie data={dataPie} options={{ responsive: true }} />
      </MDBContainer>
    );
 
}

export default AdminBody;