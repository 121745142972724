import React from 'react';
import logo from "./mdb-react.png";
import { MDBListGroup, MDBListGroupItem, MDBIcon } from 'mdbreact';
import { NavLink } from 'react-router-dom';
import './index.css';
const TopNavigation = () => {
    return (
        <div className="sidebar-fixed position-fixed">
            <a href="/mukul/home" className="logo-wrapper waves-effect">
                <img alt="MDB React Logo" className="img-fluid" src={logo}/>
            </a>
            <MDBListGroup className="list-group-flush">
                <NavLink exact={true} to="/mukul/home" activeClassName="activeClass">
                    <MDBListGroupItem>
                        <MDBIcon icon="chart-pie" className="mr-3"/>
                        Dashboard
                    </MDBListGroupItem>
                </NavLink>
                <NavLink to="/mukul/mentor" activeClassName="activeClass">
                    <MDBListGroupItem>
                        <MDBIcon icon="user-graduate" className="mr-3"/>
                        Mentor
                    </MDBListGroupItem>
                </NavLink>
                <NavLink to="/mukul/student" activeClassName="activeClass">
                    <MDBListGroupItem>
                        <MDBIcon icon="user-tie" className="mr-3"/>
                        Student
                    </MDBListGroupItem>
                </NavLink>
                <NavLink to="/mukul/indexcontact" activeClassName="activeClass">
                    <MDBListGroupItem>
                        <MDBIcon icon="comment" className="mr-3"/>
                        Home Contact
                    </MDBListGroupItem>
                </NavLink>
                <NavLink to="/mukul/mentorcontact" activeClassName="activeClass">
                    <MDBListGroupItem>
                        <MDBIcon icon="comment" className="mr-3"/>
                        Mentor Contact
                    </MDBListGroupItem>
                </NavLink>
                <NavLink to="/mukul/studentcontact" activeClassName="activeClass">
                    <MDBListGroupItem>
                        <MDBIcon icon="comment" className="mr-3"/>
                       Student Contact
                    </MDBListGroupItem>
                </NavLink>
            </MDBListGroup>
        </div>
    );
}

export default TopNavigation;