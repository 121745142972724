import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon, MDBBtn } from "mdbreact";
import Footer from '../Footer';
import Header from '../Header';

const BlogPage = () => {
  return (
    <div>
    <Header/>
    <MDBCard className="my-1 px-5 pb-5">
      <MDBCardBody>
        <MDBRow>
          <MDBCol md="12">
            <MDBCard reverse> 
              <MDBCardBody cascade className="text-center">
                <h2 className="font-weight-bold">
                  <a href="#!">Making a life-changing situation</a>
                </h2>
                <p>
                  Written by 
                  <a href="#!">
                    <strong>-Shatakshi (Student)</strong>
                  </a>
                  , 21/10/2020
                </p>
                <MDBBtn className="btn-fb waves-light">
                  <MDBIcon fab icon="facebook-f" className="pr-2" />
                  Facebook
                </MDBBtn>
                
                <MDBBtn className="btn-tw waves-light">
                  <MDBIcon fab icon="twitter" className="pr-2" />
                  Twitter
                </MDBBtn>
                
                <MDBBtn className="btn-gplus waves-light">
                  <MDBIcon fab icon="google-plus-g" className="pr-2" />
                  Google
                </MDBBtn>
                
                <MDBBtn color="default" className="waves-light">
                  <MDBIcon icon="comments" className="pr-2" />
                  Comments
                </MDBBtn>
                
              </MDBCardBody>
            </MDBCard>
            <MDBContainer className="mt-5">
              <p>
Instibuddy has been a great experience. I am the only child in my entire
family, who has cleared an exam of this level. So, it was obvious that I
won’t have mentors along with me, who would guide me in such
situation of making a life-changing situation. The mentors of Instibuddy
have been so supportive. They described everything so well. My mentor
told me everything I needed to know before entering the college. 
              </p>
              <p>
              He
also helped me deciding the branch I should study for the upcoming 4
years. I would recommend this platform to my friends, who are clueless
in this situation and wish for a helping hand.
              </p>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      
      </MDBCardBody>
    </MDBCard>
    <Footer/>
    </div>
  );
}

export default BlogPage;