import React from 'react'
import Mentorheader from '../mentor/MentorHead'
import Mentorbody from '../mentor/MentorBody'
import Footer from '../Footer'
//This is mentor homepage.
const MentorPage = ()=>{
    
    return(
           <div>
            <Mentorheader/>
            <Mentorbody/>
            <Footer/>
            </div>
    )
}

export default MentorPage;