import React ,{useState , useEffect} from "react";
import { MDBContainer, MDBRow, MDBCol, MDBInput,MDBBtn } from 'mdbreact';
import Footer from '../Footer';
import Header from "../Header";
import { Dropdown} from 'semantic-ui-react'
import { Link, useHistory } from 'react-router-dom'
import {  withRouter } from 'react-router-dom'
import firebaseall from "../../firebaseConfig";
import moment from 'moment'

const collegeOptions = [
  { key: 'Dr. B. R. Ambedkar National Institute of Technology',	value: 	'Dr. B. R. Ambedkar National Institute of Technology',text: 'Dr. B. R. Ambedkar National Institute of Technology'},
  { key: 'Indian Institute of Technology (BHU) Varanasi',	value: 	'Indian Institute of Technology (BHU) Varanasi',text: 'Indian Institute of Technology (BHU) Varanasi'},
  { key: 'Indian Institute of Technology Bhilai',	value: 	'Indian Institute of Technology Bhilai',text: 'Indian Institute of Technology Bhilai'},
  { key: 'Indian Institute of Technology Bhubaneswar',	value: 	'Indian Institute of Technology Bhubaneswar',text: 'Indian Institute of Technology Bhubaneswar'},
  { key: 'Indian Institute of Technology Bombay',	value: 	'Indian Institute of Technology Bombay',text: 'Indian Institute of Technology Bombay'},
  { key: 'Indian Institute of Technology Delhi',	value: 	'Indian Institute of Technology Delhi',text: 'Indian Institute of Technology Delhi'},
  { key: 'Indian Institute of Technology Dharwad',	value: 	'Indian Institute of Technology Dharwad',text: 'Indian Institute of Technology Dharwad'},
  { key: 'Indian Institute of Technology Gandhinagar',	value: 	'Indian Institute of Technology Gandhinagar',text: 'Indian Institute of Technology Gandhinagar'},
  { key: 'Indian Institute of Technology Goa',	value: 	'Indian Institute of Technology Goa',text: 'Indian Institute of Technology Goa'},
  { key: 'Indian Institute of Technology Guwahati',	value: 	'Indian Institute of Technology Guwahati',text: 'Indian Institute of Technology Guwahati'},
  { key: 'Indian Institute of Technology Hyderabad',	value: 	'Indian Institute of Technology Hyderabad',text: 'Indian Institute of Technology Hyderabad'},
  { key: 'Indian Institute of Technology Indore',	value: 	'Indian Institute of Technology Indore',text: 'Indian Institute of Technology Indore'},
  { key: 'Indian Institute of Technology Jammu',	value: 	'Indian Institute of Technology Jammu',text: 'Indian Institute of Technology Jammu'},
  { key: 'Indian Institute of Technology Jodhpur',	value: 	'Indian Institute of Technology Jodhpur',text: 'Indian Institute of Technology Jodhpur'},
  { key: 'Indian Institute of Technology Kanpur',	value: 	'Indian Institute of Technology Kanpur',text: 'Indian Institute of Technology Kanpur'},
  { key: 'Indian Institute of Technology Kharagpur',	value: 	'Indian Institute of Technology Kharagpur',text: 'Indian Institute of Technology Kharagpur'},
  { key: 'Indian Institute of Technology Madras',	value: 	'Indian Institute of Technology Madras',text: 'Indian Institute of Technology Madras'},
  { key: 'Indian Institute of Technology Mandi',	value: 	'Indian Institute of Technology Mandi',text: 'Indian Institute of Technology Mandi'},
  { key: 'Indian Institute of Technology Palakkad',	value: 	'Indian Institute of Technology Palakkad',text: 'Indian Institute of Technology Palakkad'},
  { key: 'Indian Institute of Technology Patna',	value: 	'Indian Institute of Technology Patna',text: 'Indian Institute of Technology Patna'},
  { key: 'Indian Institute of Technology Roorkee',	value: 	'Indian Institute of Technology Roorkee',text: 'Indian Institute of Technology Roorkee'},
  { key: 'Indian Institute of Technology Ropar',	value: 	'Indian Institute of Technology Ropar',text: 'Indian Institute of Technology Ropar'},
  { key: 'Indian Institute of Technology Tirupati',	value: 	'Indian Institute of Technology Tirupati',text: 'Indian Institute of Technology Tirupati'},
  { key: 'Indian School of Mines Dhanbad',	value: 	'Indian School of Mines Dhanbad',text: 'Indian School of Mines Dhanbad'},
  { key: 'Malaviya National Institute of Technology',	value: 	'Malaviya National Institute of Technology',text: 'Malaviya National Institute of Technology'},
  { key: 'Maulana Azad National Institute of Technology Bhopal',	value: 	'Maulana Azad National Institute of Technology Bhopal',text: 'Maulana Azad National Institute of Technology Bhopal'},
  { key: 'Motilal Nehru National Institute of Technology Allahabad',	value: 	'Motilal Nehru National Institute of Technology Allahabad',text: 'Motilal Nehru National Institute of Technology Allahabad'},
  { key: 'National Institute of Technology Agartala',	value: 	'National Institute of Technology Agartala',text: 'National Institute of Technology Agartala'},
  { key: 'National Institute of Technology Arunachal Pradesh',	value: 	'National Institute of Technology Arunachal Pradesh',text: 'National Institute of Technology Arunachal Pradesh'},
  { key: 'National Institute of Technology Calicut',	value: 	'National Institute of Technology Calicut',text: 'National Institute of Technology Calicut'},
  { key: 'National Institute of Technology Delhi',	value: 	'National Institute of Technology Delhi',text: 'National Institute of Technology Delhi'},
  { key: 'National Institute of Technology Durgapur',	value: 	'National Institute of Technology Durgapur',text: 'National Institute of Technology Durgapur'},
  { key: 'National Institute of Technology Goa',	value: 	'National Institute of Technology Goa',text: 'National Institute of Technology Goa'},
  { key: 'National Institute of Technology Hamirpur',	value: 	'National Institute of Technology Hamirpur',text: 'National Institute of Technology Hamirpur'},
  { key: 'National Institute of Technology Jamshedpur',	value: 	'National Institute of Technology Jamshedpur',text: 'National Institute of Technology Jamshedpur'},
  { key: 'National Institute of Technology Karnataka, Surathkal',	value:	'National Institute of Technology Karnataka, Surathkal',text: 'National Institute of Technology Karnataka, Surathkal'},
  { key: 'National Institute of Technology Kurukshetra',	value: 	'National Institute of Technology Kurukshetra',text: 'National Institute of Technology Kurukshetra'},
  { key: 'National Institute of Technology Manipur',	value: 	'National Institute of Technology Manipur',text: 'National Institute of Technology Manipur'},
  { key: 'National Institute of Technology Meghalaya',	value: 	'National Institute of Technology Meghalaya',text: 'National Institute of Technology Meghalaya'},
  { key: 'National Institute of Technology Mizoram',	value: 	'National Institute of Technology Mizoram',text: 'National Institute of Technology Mizoram'},
  { key: 'National Institute of Technology Nagaland',	value: 	'National Institute of Technology Nagaland',text: 'National Institute of Technology Nagaland'},
  { key: 'National Institute of Technology Patna',	value: 	'National Institute of Technology Patna',text: 'National Institute of Technology Patna'},
  { key: 'National Institute of Technology Puducherry',	value: 	'National Institute of Technology Puducherry',text: 'National Institute of Technology Puducherry'},
  { key: 'National Institute of Technology Raipur',	value: 	'National Institute of Technology Raipur',text: 'National Institute of Technology Raipur'},
  { key: 'National Institute of Technology Rourkela',	value: 	'National Institute of Technology Rourkela',text: 'National Institute of Technology Rourkela'},
  { key: 'National Institute of Technology Sikkim',	value: 	'National Institute of Technology Sikkim',text: 'National Institute of Technology Sikkim'},
  { key: 'National Institute of Technology Silchar',	value: 	'National Institute of Technology Silchar',text: 'National Institute of Technology Silchar'},
  { key: 'National Institute of Technology Srinagar',	value: 	'National Institute of Technology Srinagar',text: 'National Institute of Technology Srinagar'},
  { key: 'National Institute of Technology Tiruchirappalli',	value: 	'National Institute of Technology Tiruchirappalli',text: 'National Institute of Technology Tiruchirappalli'},
  { key: 'National Institute of Technology, Andhra Pradesh',	value: 	'National Institute of Technology, Andhra Pradesh',text: 'National Institute of Technology, Andhra Pradesh'},
  { key: 'National Institute of Technology, Uttarakhand',	value: 	'National Institute of Technology, Uttarakhand',text: 'National Institute of Technology, Uttarakhand'},
  { key: 'National Institute of Technology, Warangal',	value: 	'National Institute of Technology, Warangal',text: 'National Institute of Technology, Warangal'},
  { key: 'Sardar Vallabhbhai National Institute of Technology, Surat',	value: 	'Sardar Vallabhbhai National Institute of Technology, Surat',text: 'Sardar Vallabhbhai National Institute of Technology, Surat'},
  { key: 'Visvesvaraya National Institute of Technology, Nagpur',	value: 	'Visvesvaraya National Institute of Technology, Nagpur',text: 'Visvesvaraya National Institute of Technology, Nagpur'},
  
]
const MenteeSignUp = () => {
    const history = useHistory()
  const [name , setName] = useState('')
  const [email , setEmail] = useState('')
  const [password , setpassword] = useState('')
  const [cpassword , setcpassword] = useState('')
  const [number , setNumber] = useState('')
  const [gender , setGender] = useState('')
  const [collage , setCollage] = useState('');
  const [collageD , setDCollage] = useState('');
  const [currentValue , setCurrentValue] = useState([]);
  const [branch , setBranch] = useState('');
  const [bio , setBio] = useState('')
  const [location , setLocation] = useState('')
  const [value , setValue] =useState([]);
  const [valuef , setValuef] =useState('');


 
  // console.log(valuef)
  //Branch Selection start
  if(collage === 'Dr. B. R. Ambedkar National Institute of Technology' ){
    setCurrentValue([
    {key: ' 	Bio Technology (4 Years Bachelor of Technology)	' , value:  '	Bio Technology (4 Years Bachelor of Technology)	' ,text: '  	Bio Technology (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Textile Technology (4 Years Bachelor of Technology)	' , value:  '	Textile Technology (4 Years Bachelor of Technology)	' ,text: '  	Textile Technology (4 Years Bachelor of Technology)	 '},
    {key: ' 	Information Technology (4 Years Bachelor of Technology)	' , value:  '	Information Technology (4 Years Bachelor of Technology)	' ,text: '  	Information Technology (4 Years Bachelor of Technology)	 '},
    {key: ' 	Instrumentation and Control Engineering (4 Years Bachelor of Technology)	' , value:  '	Instrumentation and Control Engineering (4 Years Bachelor of Technology)	' ,text: '  	Instrumentation and Control Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Industrial and Production Engineering (4 Years Bachelor of Technology)	' , value:  '	Industrial and Production Engineering (4 Years Bachelor of Technology)	' ,text: '  	Industrial and Production Engineering (4 Years Bachelor of Technology)	 '},
    ])         
    setCollage('')
    } else if(collage === 'Indian Institute of Technology (BHU) Varanasi' ){
    setCurrentValue([
    {key:'    Ceramic Engineering (4 Years Bachelor of Technology)	' , value:  '	Ceramic Engineering (4 Years Bachelor of Technology)	' ,text: '  	Ceramic Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mining Engineering (4 Years Bachelor of Technology)	' , value:  '	Mining Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mining Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Pharmaceutical Engineering & Technology (4 Years Bachelor of Technology)	' , value:  '	Pharmaceutical Engineering & Technology (4 Years Bachelor of Technology)	' ,text: '  	Pharmaceutical Engineering & Technology (4 Years Bachelor of Technology)	 '},
    {key: ' 	Architecture (5 Years Bachelor of Architecture)	' , value:  '	Architecture (5 Years Bachelor of Architecture)	' ,text: '  	Architecture (5 Years Bachelor of Architecture)	 '},
    {key: ' 	Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Bioengineering with M.Tech in Biomedical Technology (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Bioengineering with M.Tech in Biomedical Technology (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Bioengineering with M.Tech in Biomedical Technology (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Ceramic Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Ceramic Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Ceramic Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Civil Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Civil Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Civil Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Electrical Engineering with M.Tech. in Power Electronics (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Electrical Engineering with M.Tech. in Power Electronics (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Electrical Engineering with M.Tech. in Power Electronics (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Materials Science and Technology (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Materials Science and Technology (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Materials Science and Technology (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Mechanical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Mechanical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Mechanical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Metallurgical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Metallurgical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Metallurgical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Mining Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Mining Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Mining Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Mathematics and Computing (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Mathematics and Computing (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Mathematics and Computing (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Engineering Physics (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Engineering Physics (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Engineering Physics (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Industrial Chemistry (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Industrial Chemistry (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Industrial Chemistry (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Pharmaceutical Engineering & Technology (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Pharmaceutical Engineering & Technology (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Pharmaceutical Engineering & Technology (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    ]) 
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Bhilai' ){
    setCurrentValue([
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Bhubaneswar' ){
    setCurrentValue([
    {key: '   Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Metallurgical and Materials Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Metallurgical and Materials Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Metallurgical and Materials Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Electrical Engineering and M.Tech Power Electronics and Drives (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Electrical Engineering and M.Tech Power Electronics and Drives (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Electrical Engineering and M.Tech Power Electronics and Drives (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Civil Engineering and M.Tech in Transportation Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Civil Engineering and M.Tech in Transportation Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Civil Engineering and M.Tech in Transportation Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Mechanical Engineering and M. Tech. in Mechanical System Design (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Mechanical Engineering and M. Tech. in Mechanical System Design (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Mechanical Engineering and M. Tech. in Mechanical System Design (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Mechanical Engineering and M. Tech. in Thermal Science & Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Mechanical Engineering and M. Tech. in Thermal Science & Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Mechanical Engineering and M. Tech. in Thermal Science & Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Civil Engineering and M. Tech. in Structural Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Civil Engineering and M. Tech. in Structural Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Civil Engineering and M. Tech. in Structural Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Mechanical Engineering with M.Tech. in Manufacturing Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Mechanical Engineering with M.Tech. in Manufacturing Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Mechanical Engineering with M.Tech. in Manufacturing Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Civil Engineering and M.Tech. in Environmental Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Civil Engineering and M.Tech. in Environmental Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Civil Engineering and M.Tech. in Environmental Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Bombay' ){
    setCurrentValue([
    {key: ' 	Aerospace Engineering (4 Years Bachelor of Technology)	' , value:  '	Aerospace Engineering (4 Years Bachelor of Technology)	' ,text: '  	Aerospace Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Engineering Physics (4 Years Bachelor of Technology)	' , value:  '	Engineering Physics (4 Years Bachelor of Technology)	' ,text: '  	Engineering Physics (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical Engineering and Materials Science (4 Years Bachelor of Technology)	' , value:  '	Metallurgical Engineering and Materials Science (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical Engineering and Materials Science (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemistry (4 Years Bachelor of Science)	' , value:  '	Chemistry (4 Years Bachelor of Science)	' ,text: '  	Chemistry (4 Years Bachelor of Science)	 '},
    {key: ' 	Economics (4 Years Bachelor of Science)	' , value:  '	Economics (4 Years Bachelor of Science)	' ,text: '  	Economics (4 Years Bachelor of Science)	 '},
    {key: ' 	BS in Mathematics (4 Years Bachelor of Science)	' , value:  '	BS in Mathematics (4 Years Bachelor of Science)	' ,text: '  	BS in Mathematics (4 Years Bachelor of Science)	 '},
    {key: ' 	Environmental Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Environmental Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Environmental Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Metallurgical Engineering & Materials Science (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Metallurgical Engineering & Materials Science (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Metallurgical Engineering & Materials Science (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Electrical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Electrical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Electrical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Energy Engineering with M.Tech. in Energy Systems Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Energy Engineering with M.Tech. in Energy Systems Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Energy Engineering with M.Tech. in Energy Systems Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Mechanical Engineering and M.Tech. in Computer Integrated Manufacturing (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Mechanical Engineering and M.Tech. in Computer Integrated Manufacturing (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Mechanical Engineering and M.Tech. in Computer Integrated Manufacturing (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Delhi' ){
    setCurrentValue([
    {key: ' 	Biotechnology and Biochemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Biotechnology and Biochemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Biotechnology and Biochemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (Power and Automation) (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (Power and Automation) (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (Power and Automation) (4 Years Bachelor of Technology)	 '},
    {key: ' 	Engineering Physics (4 Years Bachelor of Technology)	' , value:  '	Engineering Physics (4 Years Bachelor of Technology)	' ,text: '  	Engineering Physics (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mathematics and Computing (4 Years Bachelor of Technology)	' , value:  '	Mathematics and Computing (4 Years Bachelor of Technology)	' ,text: '  	Mathematics and Computing (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Production and Industrial Engineering (4 Years Bachelor of Technology)	' , value:  '	Production and Industrial Engineering (4 Years Bachelor of Technology)	' ,text: '  	Production and Industrial Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Textile Technology (4 Years Bachelor of Technology)	' , value:  '	Textile Technology (4 Years Bachelor of Technology)	' ,text: '  	Textile Technology (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Chemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Chemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Mathematics and Computing (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Mathematics and Computing (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Mathematics and Computing (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Indian Institute of Technology Dharwad	' , value:  '	Indian Institute of Technology Dharwad	' ,text: '  	Indian Institute of Technology Dharwad	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Engineering Physics (4 Years Bachelor of Technology)	' , value:  '	Engineering Physics (4 Years Bachelor of Technology)	' ,text: '  	Engineering Physics (4 Years Bachelor of Technology)	 '},
    {key: ' 	Environmental Engineering (4 Years Bachelor of Technology)	' , value:  '	Environmental Engineering (4 Years Bachelor of Technology)	' ,text: '  	Environmental Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mineral Engineering (4 Years Bachelor of Technology)	' , value:  '	Mineral Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mineral Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mining Machinery Engineering (4 Years Bachelor of Technology)	' , value:  '	Mining Machinery Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mining Machinery Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Petroleum Engineering (4 Years Bachelor of Technology)	' , value:  '	Petroleum Engineering (4 Years Bachelor of Technology)	' ,text: '  	Petroleum Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mathematics and Computing (5 YearsIntegrated Master of Technology)	' , value:  '	Mathematics and Computing (5 YearsIntegrated Master of Technology)	' ,text: '  	Mathematics and Computing (5 YearsIntegrated Master of Technology)	 '},
    {key: ' 	Applied Geology (5 Years Integrated Master of Technology)	' , value:  '	Applied Geology (5 Years Integrated Master of Technology)	' ,text: '  	Applied Geology (5 Years Integrated Master of Technology)	 '},
    {key: ' 	Applied Geophysics (5 Years Integrated Master of Technology)	' , value:  '	Applied Geophysics (5 Years Integrated Master of Technology)	' ,text: '  	Applied Geophysics (5 Years Integrated Master of Technology)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Gandhinagar' ){
    setCurrentValue([
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Materials Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Materials Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Materials Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Goa' ){
    setCurrentValue([
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mathematics and Computing (4 Years Bachelor of Technology)	' , value:  '	Mathematics and Computing (4 Years Bachelor of Technology)	' ,text: '  	Mathematics and Computing (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Guwahati' ){
    setCurrentValue([
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Engineering Physics (4 Years Bachelor of Technology)	' , value:  '	Engineering Physics (4 Years Bachelor of Technology)	' ,text: '  	Engineering Physics (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mathematics and Computing (4 Years Bachelor of Technology)	' , value:  '	Mathematics and Computing (4 Years Bachelor of Technology)	' ,text: '  	Mathematics and Computing (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Bio Technology (4 Years Bachelor of Technology)	' , value:  '	Bio Technology (4 Years Bachelor of Technology)	' ,text: '  	Bio Technology (4 Years Bachelor of Technology)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Hyderabad' ){
    setCurrentValue([
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Engineering Physics (4 Years Bachelor of Technology)	' , value:  '	Engineering Physics (4 Years Bachelor of Technology)	' ,text: '  	Engineering Physics (4 Years Bachelor of Technology)	 '},
    {key: ' 	Engineering Science (4 Years Bachelor of Technology)	' , value:  '	Engineering Science (4 Years Bachelor of Technology)	' ,text: '  	Engineering Science (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mathematics and Computing (4 Years Bachelor of Technology)	' , value:  '	Mathematics and Computing (4 Years Bachelor of Technology)	' ,text: '  	Mathematics and Computing (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Materials Science and Metallurgical Engineering (4 Years Bachelor of Technology)	' , value:  '	Materials Science and Metallurgical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Materials Science and Metallurgical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Artificial Intelligence (4 Years Bachelor of Technology)	' , value:  '	Artificial Intelligence (4 Years Bachelor of Technology)	' ,text: '  	Artificial Intelligence (4 Years Bachelor of Technology)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Indore' ){
    setCurrentValue([
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical Engineering and Materials Science (4 Years Bachelor of Technology)	' , value:  '	Metallurgical Engineering and Materials Science (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical Engineering and Materials Science (4 Years Bachelor of Technology)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Jammu' ){
    setCurrentValue([
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Materials Science and Engineering (4 Years)	' , value:  '	Materials Science and Engineering (4 Years)	' ,text: '  	Materials Science and Engineering (4 Years)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Jodhpur' ){
    setCurrentValue([
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Bio Engineering (4 Years Bachelor of Technology)	' , value:  '	Bio Engineering (4 Years Bachelor of Technology)	' ,text: '  	Bio Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Kanpur' ){
    setCurrentValue([
    {key: ' 	Aerospace Engineering (4 Years Bachelor of Technology)	' , value:  '	Aerospace Engineering (4 Years Bachelor of Technology)	' ,text: '  	Aerospace Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Biological Sciences and Bioengineering (4 Years Bachelor of Technology)	' , value:  '	Biological Sciences and Bioengineering (4 Years Bachelor of Technology)	' ,text: '  	Biological Sciences and Bioengineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Materials Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Materials Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Materials Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemistry (4 Years Bachelor of Science)	' , value:  '	Chemistry (4 Years Bachelor of Science)	' ,text: '  	Chemistry (4 Years Bachelor of Science)	 '},
    {key: ' 	Economics (4 Years Bachelor of Science)	' , value:  '	Economics (4 Years Bachelor of Science)	' ,text: '  	Economics (4 Years Bachelor of Science)	 '},
    {key: ' 	Mathematics and Scientific Computing (4 Years Bachelor of Science)	' , value:  '	Mathematics and Scientific Computing (4 Years Bachelor of Science)	' ,text: '  	Mathematics and Scientific Computing (4 Years Bachelor of Science)	 '},
    {key: ' 	Physics (4 Years Bachelor of Science)	' , value:  '	Physics (4 Years Bachelor of Science)	' ,text: '  	Physics (4 Years Bachelor of Science)	 '},
    {key: ' 	Earth Sciences (4 Years Bachelor of Science)	' , value:  '	Earth Sciences (4 Years Bachelor of Science)	' ,text: '  	Earth Sciences (4 Years Bachelor of Science)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Kharagpur' ){
    setCurrentValue([
    {key: ' 	Aerospace Engineering (4 Years Bachelor of Technology)	' , value:  '	Aerospace Engineering (4 Years Bachelor of Technology)	' ,text: '  	Aerospace Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Agricultural and Food Engineering (4 Years Bachelor of Technology)	' , value:  '	Agricultural and Food Engineering (4 Years Bachelor of Technology)	' ,text: '  	Agricultural and Food Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Biotechnology and Biochemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Biotechnology and Biochemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Biotechnology and Biochemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Electrical Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Electrical Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Electrical Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Instrumentation Engineering (4 Years Bachelor of Technology)	' , value:  '	Instrumentation Engineering (4 Years Bachelor of Technology)	' ,text: '  	Instrumentation Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Manufacturing Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Manufacturing Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Manufacturing Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mining Engineering (4 Years Bachelor of Technology)	' , value:  '	Mining Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mining Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Ocean Engineering and Naval Architecture (4 Years Bachelor of Technology)	' , value:  '	Ocean Engineering and Naval Architecture (4 Years Bachelor of Technology)	' ,text: '  	Ocean Engineering and Naval Architecture (4 Years Bachelor of Technology)	 '},
    {key: ' 	Industrial and Systems Engineering (4 Years Bachelor of Technology)	' , value:  '	Industrial and Systems Engineering (4 Years Bachelor of Technology)	' ,text: '  	Industrial and Systems Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Architecture (5 Years Bachelor of Architecture)	' , value:  '	Architecture (5 Years Bachelor of Architecture)	' ,text: '  	Architecture (5 Years Bachelor of Architecture)	 '},
    {key: ' 	Aerospace Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Aerospace Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Aerospace Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Agricultural and Food Engineering with M.Tech. in any of the listed specializations (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Agricultural and Food Engineering with M.Tech. in any of the listed specializations (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Agricultural and Food Engineering with M.Tech. in any of the listed specializations (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Biotechnology and Biochemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Biotechnology and Biochemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Biotechnology and Biochemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Chemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Chemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Chemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Civil Engineering with any of the listed specialization (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Civil Engineering with any of the listed specialization (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Civil Engineering with any of the listed specialization (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Electrical Engineering with M.Tech. in any of the listed specializations (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Electrical Engineering with M.Tech. in any of the listed specializations (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Electrical Engineering with M.Tech. in any of the listed specializations (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Electronics and Electrical Communication Engineering with M.Tech. in any of the listed specializations (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Electronics and Electrical Communication Engineering with M.Tech. in any of the listed specializations (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Electronics and Electrical Communication Engineering with M.Tech. in any of the listed specializations (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Mechanical Engineering with M.Tech. in any of the listed specializations (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Mechanical Engineering with M.Tech. in any of the listed specializations (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Mechanical Engineering with M.Tech. in any of the listed specializations (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Industrial and Systems Engineering with M.Tech. in Industrial and Systems Engineering and Management (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Industrial and Systems Engineering with M.Tech. in Industrial and Systems Engineering and Management (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Industrial and Systems Engineering with M.Tech. in Industrial and Systems Engineering and Management (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Metallurgical and Materials Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Metallurgical and Materials Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Metallurgical and Materials Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Mining Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Mining Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Mining Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Mining Safety Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Mining Safety Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Mining Safety Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Ocean Engineering and Naval Architecture (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Ocean Engineering and Naval Architecture (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Ocean Engineering and Naval Architecture (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Quality Engineering Design and Manufacturing (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Quality Engineering Design and Manufacturing (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Quality Engineering Design and Manufacturing (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Manufacturing Science and Engineering with M.Tech. in Industrial and Systems Engineering and Management (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Manufacturing Science and Engineering with M.Tech. in Industrial and Systems Engineering and Management (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Manufacturing Science and Engineering with M.Tech. in Industrial and Systems Engineering and Management (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Applied Geology (5 Years Integrated Master of Science)	' , value:  '	Applied Geology (5 Years Integrated Master of Science)	' ,text: '  	Applied Geology (5 Years Integrated Master of Science)	 '},
    {key: ' 	Chemistry (5 Years Integrated Master of Science)	' , value:  '	Chemistry (5 Years Integrated Master of Science)	' ,text: '  	Chemistry (5 Years Integrated Master of Science)	 '},
    {key: ' 	Economics (5 Years Integrated Master of Science)	' , value:  '	Economics (5 Years Integrated Master of Science)	' ,text: '  	Economics (5 Years Integrated Master of Science)	 '},
    {key: ' 	Exploration Geophysics (5 Years Integrated Master of Science)	' , value:  '	Exploration Geophysics (5 Years Integrated Master of Science)	' ,text: '  	Exploration Geophysics (5 Years Integrated Master of Science)	 '},
    {key: ' 	Mathematics and Computing (5 Years Integrated Master of Science)	' , value:  '	Mathematics and Computing (5 Years Integrated Master of Science)	' ,text: '  	Mathematics and Computing (5 Years Integrated Master of Science)	 '},
    {key: ' 	Physics (5 Years Integrated Master of Science)	' , value:  '	Physics (5 Years Integrated Master of Science)	' ,text: '  	Physics (5 Years Integrated Master of Science)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Madras' ){
    setCurrentValue([
    {key: ' 	Aerospace Engineering (4 Years Bachelor of Technology)	' , value:  '	Aerospace Engineering (4 Years Bachelor of Technology)	' ,text: '  	Aerospace Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Engineering Physics (4 Years Bachelor of Technology)	' , value:  '	Engineering Physics (4 Years Bachelor of Technology)	' ,text: '  	Engineering Physics (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Naval Architecture and Ocean Engineering (4 Years Bachelor of Technology)	' , value:  '	Naval Architecture and Ocean Engineering (4 Years Bachelor of Technology)	' ,text: '  	Naval Architecture and Ocean Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Aerospace Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Aerospace Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Aerospace Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Biological Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Biological Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Biological Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Engineering Design (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Engineering Design (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Engineering Design (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Biological Sciences (5 Years Bachelor of Science and Master of Science (Dual Degree)	' , value:  '	Biological Sciences (5 Years Bachelor of Science and Master of Science (Dual Degree)	' ,text: '  	Biological Sciences (5 Years Bachelor of Science and Master of Science (Dual Degree)	 '},
    {key: ' 	Physics (5 Years Bachelor of Science and Master of Science (Dual Degree)	' , value:  '	Physics (5 Years Bachelor of Science and Master of Science (Dual Degree)	' ,text: '  	Physics (5 Years Bachelor of Science and Master of Science (Dual Degree)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Mandi' ){
    setCurrentValue([
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Engineering Physics (4 Years Bachelor of Technology)	' , value:  '	Engineering Physics (4 Years Bachelor of Technology)	' ,text: '  	Engineering Physics (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Data Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Data Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Data Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Bio Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Bio Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Bio Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Palakkad' ){
    setCurrentValue([
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Patna' ){
    setCurrentValue([
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Bio Technology (4 Years Bachelor of Technology)	' , value:  '	Bio Technology (4 Years Bachelor of Technology)	' ,text: '  	Bio Technology (4 Years Bachelor of Technology)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Roorkee' ){
    setCurrentValue([
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Engineering Physics (4 Years Bachelor of Technology)	' , value:  '	Engineering Physics (4 Years Bachelor of Technology)	' ,text: '  	Engineering Physics (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Polymer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Polymer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Polymer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Production and Industrial Engineering (4 Years Bachelor of Technology)	' , value:  '	Production and Industrial Engineering (4 Years Bachelor of Technology)	' ,text: '  	Production and Industrial Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Architecture (5 Years Bachelor of Architecture)	' , value:  '	Architecture (5 Years Bachelor of Architecture)	' ,text: '  	Architecture (5 Years Bachelor of Architecture)	 '},
    {key: ' 	Geological Technology (5 Years Integrated Master of Technology)	' , value:  '	Geological Technology (5 Years Integrated Master of Technology)	' ,text: '  	Geological Technology (5 Years Integrated Master of Technology)	 '},
    {key: ' 	Geophysical Technology (5 Years Integrated Master of Technology)	' , value:  '	Geophysical Technology (5 Years Integrated Master of Technology)	' ,text: '  	Geophysical Technology (5 Years Integrated Master of Technology)	 '},
    {key: ' 	Applied Mathematics (5 Years Integrated Master of Science)	' , value:  '	Applied Mathematics (5 Years Integrated Master of Science)	' ,text: '  	Applied Mathematics (5 Years Integrated Master of Science)	 '},
    {key: ' 	Chemistry (5 Years Integrated Master of Science)	' , value:  '	Chemistry (5 Years Integrated Master of Science)	' ,text: '  	Chemistry (5 Years Integrated Master of Science)	 '},
    {key: ' 	 Physics (5 Years Integrated Master of Science)	' , value:  '	 Physics (5 Years Integrated Master of Science)	' ,text: '  	 Physics (5 Years Integrated Master of Science)	 '},
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Ropar' ){
    setCurrentValue([
      {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mathematics and Computing (4 Years Bachelor of Technology)	' , value:  '	Mathematics and Computing (4 Years Bachelor of Technology)	' ,text: '  	Mathematics and Computing (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Mechanical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Mechanical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    
    ])
    setCollage('')
    } else if(collage === 'Indian Institute of Technology Tirupati' ){
    setCurrentValue([
      {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    
    ])
    setCollage('')
    } else if(collage === 'Indian School of Mines Dhanbad' ){
      setCurrentValue([
        {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Engineering Physics (4 Years Bachelor of Technology)	' , value:  '	Engineering Physics (4 Years Bachelor of Technology)	' ,text: '  	Engineering Physics (4 Years Bachelor of Technology)	 '},
    {key: ' 	Environmental Engineering (4 Years Bachelor of Technology)	' , value:  '	Environmental Engineering (4 Years Bachelor of Technology)	' ,text: '  	Environmental Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mineral Engineering (4 Years Bachelor of Technology)	' , value:  '	Mineral Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mineral Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mining Engineering (4 Years Bachelor of Technology)	' , value:  '	Mining Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mining Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mining Machinery Engineering (4 Years Bachelor of Technology)	' , value:  '	Mining Machinery Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mining Machinery Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Petroleum Engineering (4 Years Bachelor of Technology)	' , value:  '	Petroleum Engineering (4 Years Bachelor of Technology)	' ,text: '  	Petroleum Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mathematics and Computing (5 Years Integrated Master of Technology)	' , value:  '	Mathematics and Computing (5 Years Integrated Master of Technology)	' ,text: '  	Mathematics and Computing (5 Years Integrated Master of Technology)	 '},
    {key: ' 	Applied Geology (5 Years Integrated Master of Technology)	' , value:  '	Applied Geology (5 Years Integrated Master of Technology)	' ,text: '  	Applied Geology (5 Years Integrated Master of Technology)	 '},
    {key: ' 	Applied Geophysics (5 Years Integrated Master of Technology)	' , value:  '	Applied Geophysics (5 Years Integrated Master of Technology)	' ,text: '  	Applied Geophysics (5 Years Integrated Master of Technology)	 '},
    
      ])
      setCollage('')
      } else if(collage === 'Malaviya National Institute of Technology' ){
      setCurrentValue([
        
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Architecture (5 Years Bachelor of Architecture)	' , value:  '	Architecture (5 Years Bachelor of Architecture)	' ,text: '  	Architecture (5 Years Bachelor of Architecture)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'Maulana Azad National Institute of Technology Bhopal' ){
      setCurrentValue([
        {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mineral Engineering (4 Years Bachelor of Technology)	' , value:  '	Mineral Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mineral Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Planning (4 Years Bachelor of Planning)	' , value:  '	Planning (4 Years Bachelor of Planning)	' ,text: '  	Planning (4 Years Bachelor of Planning)	 '},
    {key: ' 	Architecture (5 Years Bachelor of Architecture)	' , value:  '	Architecture (5 Years Bachelor of Architecture)	' ,text: '  	Architecture (5 Years Bachelor of Architecture)	 '},
    
      ])
      setCollage('')
      } else if(collage === 'Motilal Nehru National Institute of Technology Allahabad' ){
      setCurrentValue([
        {key: ' 	Bio Technology (4 Years Bachelor of Technology)	' , value:  '	Bio Technology (4 Years Bachelor of Technology)	' ,text: '  	Bio Technology (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Production and Industrial Engineering (4 Years Bachelor of Technology)	' , value:  '	Production and Industrial Engineering (4 Years Bachelor of Technology)	' ,text: '  	Production and Industrial Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Information Technology (4 Years Bachelor of Technology)	' , value:  '	Information Technology (4 Years Bachelor of Technology)	' ,text: '  	Information Technology (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Agartala' ){
      setCurrentValue([
        {key: ' 	Biotechnology and Biochemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Biotechnology and Biochemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Biotechnology and Biochemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Production Engineering (4 Years Bachelor of Technology)	' , value:  '	Production Engineering (4 Years Bachelor of Technology)	' ,text: '  	Production Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Engineering Physics (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Engineering Physics (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Engineering Physics (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Mathematics and Computing (5 Years Integrated Master of Technology)	' , value:  '	Mathematics and Computing (5 Years Integrated Master of Technology)	' ,text: '  	Mathematics and Computing (5 Years Integrated Master of Technology)	 '},
    {key: ' 	Physics (5 Years Bachelor of Science and Master of Science (Dual Degree)	' , value:  '	Physics (5 Years Bachelor of Science and Master of Science (Dual Degree)	' ,text: '  	Physics (5 Years Bachelor of Science and Master of Science (Dual Degree)	 '},
    {key: ' 	Chemistry (5 Years Bachelor of Science and Master of Science (Dual Degree)	' , value:  '	Chemistry (5 Years Bachelor of Science and Master of Science (Dual Degree)	' ,text: '  	Chemistry (5 Years Bachelor of Science and Master of Science (Dual Degree)	 '},
    
      ])
      setCollage('')
      } else if(collage === 'National Institute of Technology Arunachal Pradesh' ){
      setCurrentValue([
        {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Calicut' ){
      setCurrentValue([
        {key: ' 	Bio Technology (4 Years Bachelor of Technology)	' , value:  '	Bio Technology (4 Years Bachelor of Technology)	' ,text: '  	Bio Technology (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Engineering Physics (4 Years Bachelor of Technology)	' , value:  '	Engineering Physics (4 Years Bachelor of Technology)	' ,text: '  	Engineering Physics (4 Years Bachelor of Technology)	 '},
    {key: ' 	Materials Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Materials Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Materials Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Production Engineering (4 Years Bachelor of Technology)	' , value:  '	Production Engineering (4 Years Bachelor of Technology)	' ,text: '  	Production Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Architecture (5 Years Bachelor of Architecture)	' , value:  '	Architecture (5 Years Bachelor of Architecture)	' ,text: '  	Architecture (5 Years Bachelor of Architecture)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Delhi' ){
      setCurrentValue([
        {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Durgapur' ){
      setCurrentValue([
        {key: ' 	Bio Technology (4 Years Bachelor of Technology)	' , value:  '	Bio Technology (4 Years Bachelor of Technology)	' ,text: '  	Bio Technology (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Chemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Chemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Biotechnology (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Biotechnology (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Biotechnology (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Chemistry (5 Years Integrated Master of Science)	' , value:  '	Chemistry (5 Years Integrated Master of Science)	' ,text: '  	Chemistry (5 Years Integrated Master of Science)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Goa' ){
      setCurrentValue([
        {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Hamirpur' ){
      setCurrentValue([
        {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Materials Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Materials Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Materials Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Architecture (5 Years Bachelor of Architecture)	' , value:  '	Architecture (5 Years Bachelor of Architecture)	' ,text: '  	Architecture (5 Years Bachelor of Architecture)	 '},
    {key: ' 	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Computer Science and Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Electronics and Communication Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Electronics and Communication Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Electronics and Communication Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Jamshedpur' ){
      setCurrentValue([
        {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Production and Industrial Engineering (4 Years Bachelor of Technology)	' , value:  '	Production and Industrial Engineering (4 Years Bachelor of Technology)	' ,text: '  	Production and Industrial Engineering (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Karnataka Surathkal' ){
      setCurrentValue([
        {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mining Engineering (4 Years Bachelor of Technology)	' , value:  '	Mining Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mining Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Information Technology (4 Years Bachelor of Technology)	' , value:  '	Information Technology (4 Years Bachelor of Technology)	' ,text: '  	Information Technology (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Kurukshetra' ){
      setCurrentValue([
        {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Production and Industrial Engineering (4 Years Bachelor of Technology)	' , value:  '	Production and Industrial Engineering (4 Years Bachelor of Technology)	' ,text: '  	Production and Industrial Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Information Technology (4 Years Bachelor of Technology)	' , value:  '	Information Technology (4 Years Bachelor of Technology)	' ,text: '  	Information Technology (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Engineering (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Manipur' ){
      setCurrentValue([
        {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Meghalaya' ){
      setCurrentValue([
        {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Mizoram' ){
      setCurrentValue([
        {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Nagaland' ){
      setCurrentValue([
        {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Patna' ){
      setCurrentValue([
        {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Architecture (5 Years Bachelor of Architecture)	' , value:  '	Architecture (5 Years Bachelor of Architecture)	' ,text: '  	Architecture (5 Years Bachelor of Architecture)	 '},
    {key: ' 	Chemistry (5 Years Integrated Master of Science)	' , value:  '	Chemistry (5 Years Integrated Master of Science)	' ,text: '  	Chemistry (5 Years Integrated Master of Science)	 '},
    {key: ' 	Physics (5 Years Integrated Master of Science)	' , value:  '	Physics (5 Years Integrated Master of Science)	' ,text: '  	Physics (5 Years Integrated Master of Science)	 '},
    {key: ' 	Mathematics (5 Years Integrated Master of Science)	' , value:  '	Mathematics (5 Years Integrated Master of Science)	' ,text: '  	Mathematics (5 Years Integrated Master of Science)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Puducherry' ){
      setCurrentValue([
        {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Raipur' ){
      setCurrentValue([
        {key: ' 	Bio Technology (4 Years Bachelor of Technology)	' , value:  '	Bio Technology (4 Years Bachelor of Technology)	' ,text: '  	Bio Technology (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mining Engineering (4 Years Bachelor of Technology)	' , value:  '	Mining Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mining Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Information Technology (4 Years Bachelor of Technology)	' , value:  '	Information Technology (4 Years Bachelor of Technology)	' ,text: '  	Information Technology (4 Years Bachelor of Technology)	 '},
    {key: ' 	Bio Medical Engineering (4 Years Bachelor of Technology)	' , value:  '	Bio Medical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Bio Medical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Architecture (5 Years Bachelor of Architecture)	' , value:  '	Architecture (5 Years Bachelor of Architecture)	' ,text: '  	Architecture (5 Years Bachelor of Architecture)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Rourkela' ){
      setCurrentValue([
        {key: ' 	Bio Technology (4 Years Bachelor of Technology)	' , value:  '	Bio Technology (4 Years Bachelor of Technology)	' ,text: '  	Bio Technology (4 Years Bachelor of Technology)	 '},
    {key: ' 	Ceramic Engineering (4 Years Bachelor of Technology)	' , value:  '	Ceramic Engineering (4 Years Bachelor of Technology)	' ,text: '  	Ceramic Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mining Engineering (4 Years Bachelor of Technology)	' , value:  '	Mining Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mining Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Bio Medical Engineering (4 Years Bachelor of Technology)	' , value:  '	Bio Medical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Bio Medical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Industrial Design (4 Years Bachelor of Technology)	' , value:  '	Industrial Design (4 Years Bachelor of Technology)	' ,text: '  	Industrial Design (4 Years Bachelor of Technology)	 '},
    {key: ' 	Food Process Engineering (4 Years Bachelor of Technology)	' , value:  '	Food Process Engineering (4 Years Bachelor of Technology)	' ,text: '  	Food Process Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Architecture (5 Years Bachelor of Architecture)	' , value:  '	Architecture (5 Years Bachelor of Architecture)	' ,text: '  	Architecture (5 Years Bachelor of Architecture)	 '},
    {key: ' 	Chemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Chemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Chemical Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Metallurgical and Materials Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Metallurgical and Materials Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Metallurgical and Materials Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Mining Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Mining Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Mining Engineering (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Ceramic Engineering and M.Tech Industrial Ceramic (5 Years Bachelor and Master of Technology (Dual Degree)	' , value:  '	Ceramic Engineering and M.Tech Industrial Ceramic (5 Years Bachelor and Master of Technology (Dual Degree)	' ,text: '  	Ceramic Engineering and M.Tech Industrial Ceramic (5 Years Bachelor and Master of Technology (Dual Degree)	 '},
    {key: ' 	Chemistry (5 Years Integrated Master of Science)	' , value:  '	Chemistry (5 Years Integrated Master of Science)	' ,text: '  	Chemistry (5 Years Integrated Master of Science)	 '},
    {key: ' 	Physics (5 Years Integrated Master of Science)	' , value:  '	Physics (5 Years Integrated Master of Science)	' ,text: '  	Physics (5 Years Integrated Master of Science)	 '},
    {key: ' 	Mathematics (5 Years Integrated Master of Science)	' , value:  '	Mathematics (5 Years Integrated Master of Science)	' ,text: '  	Mathematics (5 Years Integrated Master of Science)	 '},
    {key: ' 	Life Science (5 Years Integrated Master of Science)	' , value:  '	Life Science (5 Years Integrated Master of Science)	' ,text: '  	Life Science (5 Years Integrated Master of Science)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Sikkim	' ){
      setCurrentValue([
        {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Silchar' ){
      setCurrentValue([
        {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Instrumentation Engineering (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Srinagar' ){
      setCurrentValue([
        {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Information Technology (4 Years Bachelor of Technology)	' , value:  '	Information Technology (4 Years Bachelor of Technology)	' ,text: '  	Information Technology (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Tiruchirappalli' ){
      setCurrentValue([
        {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Production Engineering (4 Years Bachelor of Technology)	' , value:  '	Production Engineering (4 Years Bachelor of Technology)	' ,text: '  	Production Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Instrumentation and Control Engineering (4 Years Bachelor of Technology)	' , value:  '	Instrumentation and Control Engineering (4 Years Bachelor of Technology)	' ,text: '  	Instrumentation and Control Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Architecture (5 Years Bachelor of Architecture)	' , value:  '	Architecture (5 Years Bachelor of Architecture)	' ,text: '  	Architecture (5 Years Bachelor of Architecture)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Andhra Pradesh' ){
      setCurrentValue([
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Uttarakhand' ){
      setCurrentValue([
      ])
      setCollage('')
    } else if(collage === 'National Institute of Technology Warangal' ){
      setCurrentValue([
        {key: ' 	Bio Technology (4 Years Bachelor of Technology)	' , value:  '	Bio Technology (4 Years Bachelor of Technology)	' ,text: '  	Bio Technology (4 Years Bachelor of Technology)	 '},
    {key: 'Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'Sardar Vallabhbhai National Institute of Technology Surat' ){
      setCurrentValue([
        {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Chemistry (5 Years Integrated Master of Science)	' , value:  '	Chemistry (5 Years Integrated Master of Science)	' ,text: '  	Chemistry (5 Years Integrated Master of Science)	 '},
    {key: ' 	Physics (5 Years Integrated Master of Science)	' , value:  '	Physics (5 Years Integrated Master of Science)	' ,text: '  	Physics (5 Years Integrated Master of Science)	 '},
    {key: ' 	Mathematics (5 Years Integrated Master of Science)	' , value:  '	Mathematics (5 Years Integrated Master of Science)	' ,text: '  	Mathematics (5 Years Integrated Master of Science)	 '},
    
      ])
      setCollage('')
    } else if(collage === 'Visvesvaraya National Institute of Technology Nagpur' ){
      setCurrentValue([
        {key: ' 	Chemical Engineering (4 Years Bachelor of Technology)	' , value:  '	Chemical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Chemical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Civil Engineering (4 Years Bachelor of Technology)	' , value:  '	Civil Engineering (4 Years Bachelor of Technology)	' ,text: '  	Civil Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Computer Science and Engineering (4 Years Bachelor of Technology)	' , value:  '	Computer Science and Engineering (4 Years Bachelor of Technology)	' ,text: '  	Computer Science and Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' , value:  '	Electronics and Communication Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electronics and Communication Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mechanical Engineering (4 Years Bachelor of Technology)	' , value:  '	Mechanical Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mechanical Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' , value:  '	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	' ,text: '  	Metallurgical and Materials Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Mining Engineering (4 Years Bachelor of Technology)	' , value:  '	Mining Engineering (4 Years Bachelor of Technology)	' ,text: '  	Mining Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' , value:  '	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	' ,text: '  	Electrical and Electronics Engineering (4 Years Bachelor of Technology)	 '},
    {key: ' 	Architecture (5 Years Bachelor of Architecture)	' , value:  '	Architecture (5 Years Bachelor of Architecture)	' ,text: '  	Architecture (5 Years Bachelor of Architecture)	 '},
    
      ])
      setCollage('')
    }

    const getCollage =(e,data)=>{
      setDCollage(data.value)
       setCollage(data.value)
    }
    
    const getBranch = ( e , data )=>{
        setBranch(data.value)
    }
 //Branch selection end
    if(value.length > 0){
      // console.log(value.indexOf(valuef)) 
        if(value.indexOf(valuef) >=0){
          alert('you are getting 10 minute')
        }else if(valuef === '') {
          
        }
  
     }


   const date = moment().format("YYYY-MM-DD hh:mm");
   const submitForm =(e)=>{
   e.preventDefault()
   if(password !== cpassword){
    alert('use Same password')
   }else if (password.length < 8) {
     alert('password less than 8')
   }else{ 
   firebaseall.auth().createUserWithEmailAndPassword(email, password).then((users )=>{
    firebaseall.database().ref('users').child(users.user.uid).set({
      name , email , number,gender,bio, location, date ,collageD,branch
    })
     users.user.sendEmailVerification()
   }).catch(function(error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // [START_EXCLUDE]
    if (errorCode == 'auth/weak-password') {
      alert('The password is too weak.');
    } else {
      alert(errorMessage);
    }
    // console.log(error);
    // [END_EXCLUDE]
  });
 

  history.push('/student/home')
  }


}

// console.log(collage)
// console.log(collageD)

return (

  <div >
    <Header/>
  <div >
<MDBContainer className="mt-5">
  <MDBRow className="d-flex justify-content-center">
    <MDBCol md="6">
    <form  onSubmit={(e)=>submitForm(e)} >
        <p className="h5 text-center mb-4">Student Sign up</p>
        <div className="orange-text">
          <MDBInput label="Your name" icon="user" group type="text" validate error="wrong"
            success="right"  onChange={(e)=>setName(e.target.value)}  required/>
          <MDBInput label="Your email" icon="envelope" group type="email" validate error="wrong"
            success="right"  onChange={(e)=>setEmail(e.target.value)} required/>
            <MDBInput label="Your password" icon="lock" group type="password" validate  onChange={(e)=>setpassword(e.target.value)}  required/>
            <MDBInput label="Confirm password" icon="lock" group type="password" validate  onChange={(e)=>setcpassword(e.target.value)}  required/>
            <MDBInput label="Your mobile number" icon="mobile" group type="phone" validate error="wrong"
            success="right"  onChange={(e)=>setNumber(e.target.value)} required/>
            <select className="browser-default custom-select" value={gender} onChange={(e)=>setGender(e.target.value)} required >
                           <option value=''>Choose your gender</option>
                           <option value="Male">Male</option>
                           <option value="Female">Female</option>
                           <option value="Other">Other</option>
                        </select>
            <div className="mt-5 text-left ">
            Select your college and branch
    <MDBRow className="h6 display-5 d-flex justify-content-center">
      
      <MDBCol className="mt-2"> 
  <Dropdown
    onChange={getCollage}
    placeholder='Select or search your college'
    fluid
    search
    selection
    required
    options={collegeOptions}/>
    
  </MDBCol>
 
  </MDBRow>
  <MDBRow>
  <MDBCol className="mt-2"> 
  <Dropdown
   onChange={getBranch}
    placeholder='Select or search your Branch'
    fluid
    search
    selection
    required
    options={currentValue}
  />
  </MDBCol>
  </MDBRow>
    </div>
    <MDBInput
            value={location}
            onChange={(e)=>
        setLocation(e.target.value)}
        label="Your Location(State)"
        icon="map-marker-alt"
        group
        type="text"
        error="wrong"
        success="right"
        required
        />
    <MDBInput
                value={bio}
                onChange={(e)=>
            setBio(e.target.value)}
            label="Hobbies"
            icon="info"
            group
            type="text"
            validate
            required />
        </div>
        <div className="custom-control custom-checkbox">
        <input type="checkbox" className="custom-control-input" id="defaultUnchecked" required></input>
        <label className="custom-control-label" for="defaultUnchecked">I agreee to the <Link to='/terms' className="text-danger">terms and conditions</Link></label>
      </div>
        <div className="text-center">
          <MDBBtn className="instibuddy-bg text-white" type='submit' >Register</MDBBtn>
        </div>
      </form>
    </MDBCol>
  </MDBRow>
</MDBContainer>
<Footer/>
     </div>
     </div>
  );
} 

export default withRouter(MenteeSignUp);