import React from 'react';
import {Card} from 'react-bootstrap'
import Pratyush from '../image/team/Pratyush.png'
import Ayush from '../image/team/Ayush.png'
import Anusar from '../image/team/Anusar.jpeg'
// import Naman from '../image/team/Naman.jpg'
import Sunny from '../image/team/Sunny.jpg'
import Shivam from '../image/team/Shivam.jpg'
import Pawan from '../image/team/Pawan.jpg'
import Krishna from '../image/team/Krishna.png'
import Mukul from '../image/team/Mukul.png'
import Masoom from '../image/team/Masoom.jpg'
import Aileen from '../image/team/Aileen.jpg'
import Aman from '../image/team/Aman.jpg'
// import Iqra from '../image/team/Iqra.png'
import Dipshikha from '../image/team/Dipshikha.png'
import KumarShiv from '../image/Shiv.jpeg'
import Gaurav from '../image/team/Gaurav.jpg'
import Aparna from '../image/team/Aparna.jpg'
import Tejaswi from '../image/team/Tejaswi.png'
import Praveen from '../image/team/Praveen.jpg'
import { MDBRow, MDBCol, MDBJumbotron,MDBIcon, MDBContainer } from 'mdbreact';
import Header from './Header';
import Footer from './Footer';
const Team=()=>{

return( 
  <div>
      <Header/>
      <MDBJumbotron className="bannerbg">
   <h1 className='font-weight-bold mb-0 pt-md-3 text-center text-white'>
      Meet the team
   </h1>
</MDBJumbotron>
<MDBContainer>
<h3 className='display-5 font-weight-bold mb-5 mt-5 pt-md-3 text-center '>
   Advisory Board 
                </h3>
<div className='d-flex mb-5'>
     
     <MDBRow>
        <MDBCol md="3" className="mt-2" style={{ display:'flex', justifyContent:'center' }} >
           <Card style={{ width: '20rem' }}>
           <Card.Img variant="top" className='rounded-circle p-3' src={Gaurav}/>
           <Card.Body>
              <Card.Title className='text-center'>Gaurav Gutgutia</Card.Title>
              <Card.Text className='text-center ' style={{ fontWeight: 'bold' }}>
              Co-founder, COO , GrabbnGo Pvt. Ltd.
              </Card.Text>
              
           </Card.Body>
           </Card>
        </MDBCol>
        <MDBCol md="3" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
           <Card style={{ width: '20rem' }}>
           <Card.Img variant="top" className='rounded-circle p-3' src={Praveen}/>
           <Card.Body>
              <Card.Title className='text-center'>Praveen B Malla</Card.Title>
              <Card.Text className='text-center  font-weight-bold' >
              Director, IIT-Bombay Alumni Association and Managing Director, AM innovations
              </Card.Text>
              
           </Card.Body>
           </Card>
        </MDBCol>
        <MDBCol md="3" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
           <Card style={{ width: '20rem' }}>
           <Card.Img variant="top" className='rounded-circle p-3' src={KumarShiv}/>
           <Card.Body>
              <Card.Title className='text-center'>Kumar Shivashish</Card.Title>
              <Card.Text className='text-center  font-weight-bold' >
              IPS Officer and IIT Kharagpur Alumni
              </Card.Text>
              
           </Card.Body>
           </Card>
        </MDBCol>
        <MDBCol md="3" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
           <Card style={{ width: '20rem' }}>
           <Card.Img variant="top" className='rounded-circle p-3' src={Aparna}/>
           <Card.Body>
              <Card.Title className='text-center'>Aparna Rao</Card.Title>
              <Card.Text className='text-center  font-weight-bold' >
             Senior Manager and Professor IIT Bombay
              </Card.Text>
              
           </Card.Body>
           </Card>
        </MDBCol>
     </MDBRow>
      
  </div>
  <div className='d-flex justify-content-center mb-5 mt-5'>
   <MDBRow>
      
      <MDBCol md="6" className="mt-2">
           <Card style={{ width: '20rem' }}>
           <Card.Img variant="top" className='rounded-circle p-3' src={Tejaswi}/>
           <Card.Body>
              <Card.Title className='text-center'>Tejaswi Priyadarshi</Card.Title>
              <Card.Text className='text-center ' style={{ fontWeight: 'bold' }}>
              National Sales Manager , Bombay Hemp Company and alumni XLRI,Jamshedpur
              </Card.Text>
              
           </Card.Body>
           </Card>
        </MDBCol>
      
   </MDBRow>
   </div>
  <h3 className='display-5 font-weight-bold mb-5 mt-5 pt-md-3 text-center '>
  Co-Founders
                </h3>
   <div className='d-flex justify-content-between mb-5'>
     
      <MDBRow className="h6 display-5 m-auto">
         <MDBCol md="6" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
            <Card style={{ width: '20rem' }}>
            <Card.Img variant="top" className='rounded-circle p-3' src={Pratyush}/>
            <Card.Body>
               <Card.Title className='text-center'>Pratyush Kamal</Card.Title>
               <Card.Text className='text-center ' style={{ fontWeight: 'bold' }}>
               Co-founder & CEO
               </Card.Text>
               <Card.Text className='text-center ' style={{ fontWeight: 'bold' }}>
               IIT-Bombay
               </Card.Text>
               <ul className="list-unstyled text-center">
                  <a rel="noopener noreferrer" href="https://www.facebook.com/pratyush.kamal.98" target="_blank"   className="p-2 fa-lg">
                     <MDBIcon fab icon="facebook-f" className="blue-text" />
                  </a>
                  
                  <a rel="noopener noreferrer" href="https://www.linkedin.com/in/pratyush-kamal-3b8152148" target="_blank"   className="p-2 fa-lg">
                     <MDBIcon fab icon="linkedin" className="blue-text" />
                  </a>
               </ul>
            </Card.Body>
            </Card>
         </MDBCol>
         <MDBCol md="6" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
            <Card style={{ width: '20rem' }}>
            <Card.Img variant="top" className='rounded-circle p-3' src={Ayush}/>
            <Card.Body>
               <Card.Title className='text-center'>Ayush Raj</Card.Title>
               <Card.Text className='text-center  font-weight-bold' >
               Co-founder & COO
               </Card.Text>
               <Card.Text className='text-center ' style={{ fontWeight: 'bold' }}>
               IIT-Bombay
               </Card.Text>
               <ul className="list-unstyled text-center">
                  <a rel="noopener noreferrer" href="https://m.facebook.com/ayushraj.054" target="_blank"   className="p-2 fa-lg">
                     <MDBIcon fab icon="facebook-f" className="blue-text" />
                  </a>
                  
                  <a rel="noopener noreferrer" href="https://in.linkedin.com/in/raj-ayush" target="_blank"   className="p-2 fa-lg">
                     <MDBIcon fab icon="linkedin" className="blue-text" />
                  </a>
               </ul>
            </Card.Body>
            </Card>
         </MDBCol>
      </MDBRow>
   </div>
   <h3 className='display-5 font-weight-bold mb-5 pt-md-3 text-center '>
   Business Management Team
                </h3>
   <MDBRow>
      <MDBCol md="4" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
         <Card style={{ width: '20rem' }}>
         <Card.Img variant="top" className=' p-3' src={Sunny}/>
         <Card.Body>
            <Card.Title className='text-center'>Sunny Dhondkar</Card.Title>
            <ul className="list-unstyled text-center">
               <a rel="noopener noreferrer" href="https://www.facebook.com/sunny.dhondkar.1" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="facebook-f" className="blue-text" />
               </a>
              
               <a rel="noopener noreferrer" href="https://www.linkedin.com/in/sunnydhondkar" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="linkedin" className="blue-text" />
               </a>
            </ul>
         </Card.Body>
         </Card>
      </MDBCol>
      <MDBCol md="4" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
         <Card style={{ width: '20rem' }}>
         <Card.Img variant="top" className=' p-3' src={Pawan}/>
         <Card.Body>
            <Card.Title className='text-center'>Pawan Mavlia</Card.Title>
            <ul className="list-unstyled text-center">
               <a rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100037822874317" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="facebook-f" className="blue-text" />
               </a>
              
               <a rel="noopener noreferrer" href="https://www.linkedin.com/in/pawan-mavlia-a748171a9" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="linkedin" className="blue-text" />
               </a>
            </ul>
         </Card.Body>
         </Card>
      </MDBCol>
      <MDBCol md="4" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
         <Card style={{ width: '20rem' }}>
         <Card.Img variant="top" className=' p-3' src={Anusar}/>
         <Card.Body>
            <Card.Title className='text-center'>Anusar Modi</Card.Title>
            <ul className="list-unstyled text-center">
               <a rel="noopener noreferrer" href="https://www.facebook.com/anusar.modi.1" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="facebook-f" className="blue-text" />
               </a>
              
               <a rel="noopener noreferrer" href="https://in.linkedin.com/in/anusar-modi-9371b2191" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="linkedin" className="blue-text" />
               </a>
            </ul>
         </Card.Body>
         </Card>
      </MDBCol>
      {/* <MDBCol md="3" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
      <Card style={{ width: '20rem' }}>
         <Card.Img variant="top" className='p-3' src={Shivam}/>
         <Card.Body>
            <Card.Title className='text-center'>Shivam Joshi</Card.Title>
            <ul className="list-unstyled text-center">
               <a rel="noopener noreferrer" href="#!" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="facebook-f" className="blue-text" />
               </a>
              
               <a rel="noopener noreferrer" href="#!" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="linkedin" className="blue-text" />
               </a>
            </ul>
         </Card.Body>
         </Card>
         
      </MDBCol> */}
   </MDBRow>
   {/* <div className='d-flex justify-content-center mb-5 mt-5'>
   <MDBRow className="display-5 m-auto">
      <MDBCol md="6" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
      <Card style={{ width: '20rem' }}>
         <Card.Img variant="top" className=' p-3' src={Naman}/>
         <Card.Body>
            <Card.Title className='text-center'>Naman Saraf</Card.Title>
            <ul className="list-unstyled text-center">
               <a rel="noopener noreferrer" href="#!" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="facebook-f" className="blue-text" />
               </a>
              
               <a rel="noopener noreferrer" href="#!" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="linkedin" className="blue-text" />
               </a>
            </ul>
         </Card.Body>
         </Card>
      </MDBCol>
      
   </MDBRow>
   </div> */}
   
     
     <h3 className='display-5 font-weight-bold mb-5 pt-md-3 text-center '>
   Technical Team
                </h3>
       <div className='d-flex justify-content-between mb-5 mt-5'>
                  
                  <MDBRow className="display-5 m-auto">
                     <MDBCol md="6" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
                        <Card style={{ width: '20rem' }}>
                        <Card.Img variant="top" className='p-3' src={Krishna}/>
                        <Card.Body>
                           <Card.Title className='text-center'>Krishana Nishad</Card.Title>
                           <ul className="list-unstyled text-center">
                              <a rel="noopener noreferrer" href="https://github.com/Krishnao1" target="_blank"   className="p-2 fa-lg">
                                 <MDBIcon fab icon="github" className="blue-text" />
                              </a>
                              
                              <a rel="noopener noreferrer" href="https://www.linkedin.com/in/krishna-nishad-5525b2171" target="_blank"   className="p-2 fa-lg">
                                 <MDBIcon fab icon="linkedin" className="blue-text" />
                              </a>
                           </ul>
                        </Card.Body>
                        </Card>
                     </MDBCol>
                     <MDBCol md="6" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
                        <Card style={{ width: '20rem' }}>
                        <Card.Img variant="top" className='p-3' src={Mukul}/>
                        <Card.Body>
                           <Card.Title className='text-center'>Mukul Rai</Card.Title>
                           <ul className="list-unstyled text-center">
                              <a rel="noopener noreferrer" href="https://github.com/raimukul" target="_blank"   className="p-2 fa-lg">
                                 <MDBIcon fab icon="github" className="blue-text" />
                              </a>
                              
                              <a rel="noopener noreferrer" href="https://www.linkedin.com/in/raimukul/" target="_blank"   className="p-2 fa-lg">
                                 <MDBIcon fab icon="linkedin" className="blue-text" />
                              </a>
                           </ul>
                        </Card.Body>
                        </Card>
                     </MDBCol>
                     
                  </MDBRow>
                  </div>

                  <h3 className='display-5 font-weight-bold mb-5 pt-md-3 text-center '>
     Design Team
                </h3>
   <MDBRow className="display-5 m-auto">
      <MDBCol md="4" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
         <Card style={{ width: '20rem' }}>
         <Card.Img variant="top" className='p-3' src={Masoom}/>
         <Card.Body>
            <Card.Title className='text-center'>Masoom Rajput</Card.Title>
            <ul className="list-unstyled text-center">
            <a rel="noopener noreferrer" href="https://www.behance.net/masoomrajput" target="_blank"   className="p-2 fa-lg">
                     <MDBIcon fab icon="behance" className="blue-text" />
                  </a>
            <a rel="noopener noreferrer" href="https://www.instagram.com/_not_so_masoom_/" target="_blank"   className="p-2 fa-lg">
                     <MDBIcon fab icon="instagram" className="blue-text" />
                  </a>
               <a rel="noopener noreferrer" href="https://www.linkedin.com/in/masoom-r-326773119/" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="linkedin" className="blue-text" />
               </a>
            </ul>
         </Card.Body>
         </Card>
      </MDBCol>
      {/* <MDBCol md="3" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
         <Card style={{ width: '20rem' }}>
         <Card.Img variant="top" className='p-3' src={Aman}/>
         <Card.Body>
            <Card.Title className='text-center'>Aman Sathe</Card.Title>
            <ul className="list-unstyled text-center">
            <a rel="noopener noreferrer" href="https://www.instagram.com/60ayy_/" target="_blank"   className="p-2 fa-lg">
                     <MDBIcon fab icon="instagram" className="blue-text" />
                  </a>
               <a rel="noopener noreferrer" href="https://www.linkedin.com/in/aman-sathe-2ba56b1b6/" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="linkedin" className="blue-text" />
               </a>
            </ul>
         </Card.Body>
         </Card>
      </MDBCol> */}
      <MDBCol md="4" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
         <Card style={{ width: '20rem' }}>
         <Card.Img variant="top" className='p-3' src={Aileen}/>
         <Card.Body>
            <Card.Title className='text-center'>Aileen Sequeira</Card.Title>
            <ul className="list-unstyled text-center">
            <a rel="noopener noreferrer" href="https://www.behance.net/alexsequeira" target="_blank"   className="p-2 fa-lg">
                     <MDBIcon fab icon="behance" className="blue-text" />
                  </a>
            <a rel="noopener noreferrer" href="https://www.instagram.com/ddabbler13/" target="_blank"   className="p-2 fa-lg">
                     <MDBIcon fab icon="instagram" className="blue-text" />
                  </a>
               {/* <a rel="noopener noreferrer" href="#!" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="linkedin" className="blue-text" />
               </a> */}
            </ul>
         </Card.Body>
         </Card>
      </MDBCol>
      <MDBCol md="4" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
         <Card style={{ width: '20rem' }}>
         <Card.Img variant="top" className='p-3' src={Dipshikha}/>
         <Card.Body>
            <Card.Title className='text-center'>Dipshikha Dutta</Card.Title>
            <ul className="list-unstyled text-center">
            <a rel="noopener noreferrer" href="https://www.behance.net/orchid_love_design" target="_blank"   className="p-2 fa-lg">
                     <MDBIcon fab icon="behance" className="blue-text" />
                  </a>
            <a rel="noopener noreferrer" href="https://www.instagram.com/orchid_love_design/" target="_blank"   className="p-2 fa-lg">
                     <MDBIcon fab icon="instagram" className="blue-text" />
                  </a>
               <a rel="noopener noreferrer" href="https://www.linkedin.com/mwlite/in/dipshikha-dutta-a81b7018b" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="linkedin" className="blue-text" />
               </a>
            </ul>
         </Card.Body>
         </Card>
      </MDBCol>
      {/* <MDBCol md="3" className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
         <Card style={{ width: '20rem' }}>
         <Card.Img variant="top" className='p-3' src={Iqra}/>
         <Card.Body>
            <Card.Title className='text-center'>Iqra Belim</Card.Title>
            <ul className="list-unstyled text-center">
            <a rel="noopener noreferrer" href="#!" target="_blank"   className="p-2 fa-lg">
                     <MDBIcon fab icon="instagram" className="blue-text" />
                  </a>
               <a rel="noopener noreferrer" href="#!" target="_blank"   className="p-2 fa-lg">
                  <MDBIcon fab icon="linkedin" className="blue-text" />
               </a>
            </ul>
         </Card.Body>
         </Card>
      </MDBCol> */}
      
   </MDBRow>






   </MDBContainer>
                  <Footer/>
                  </div>
          
        )
}

export default Team;