import React, { useEffect, useState }  from "react";
import {MDBRow, MDBCol, MDBIcon, MDBContainer} from "mdbreact";
import { withRouter , useHistory } from 'react-router-dom'
import Banner from '../../image/studentBannner.png'

const Studentpage = () => {
 
  return (
  	<div className="text-center">
       
       <MDBContainer>
          <section className="text-center my-5">
        <h2 className="h1-responsive font-weight-bold my-5">
        We will shortly connect you to your college Buddy via email/call.
        </h2>
        <img src={Banner} className="img-fluid" alt="" />
        
      </section>
      <section className="text-center my-5 mt-2">
        
      </section>
      </MDBContainer>
    </div>
  );
}

export default withRouter(Studentpage);