import React, { useState } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBCard } from 'mdbreact';
import firebaseall from '../../firebaseConfig'


const PopupMentor = ()=>{
    const [mail ,setMail] = useState('')

    firebaseall.auth().onAuthStateChanged((user)=>{
        if(user){
            setMail(user.email)
        }
        
    })

    const sendEmail =()=>{
      
        firebaseall.auth().onAuthStateChanged((user)=>{
            if(user){
               user.sendEmailVerification()
            }
        })
        alert('mail sent successfully')
    }
     
// console.log(mail)

    return (
<MDBContainer className="mt-5 ">
  <MDBRow className="d-flex justify-content-center "> 
    <MDBCol md="6" className="p-2">
    <MDBCard className="p-4 cloudy-knoxville-gradient color-block-5 ">
        <h3 className="text-center orange-text">Verify your email to finish signing up for Instibuddy.</h3>
        <hr></hr>
        <p>Thank you for choosing Instibuddy</p>
        <h5>We have sent an email to "" {mail}  ""  to verify your address. Please click the link in that email to continue.</h5>
         <button onClick={()=>sendEmail()}> verify email</button>
    </MDBCard>
    </MDBCol>
    </MDBRow>
    </MDBContainer>

        )
    }
    
    export default PopupMentor;