import React from 'react';
import { MDBFooter, MDBIcon } from 'mdbreact';

const Footer = () => {
    return (
        <MDBFooter color="special-color-dark" className="text-center font-small darken-2 mt-2">
            <div className="pb-4">
            <a rel="noopener noreferrer" href="https://www.facebook.com/instibuddyapp/" target="_blank"><MDBIcon fab icon="facebook" className="mr-3"/></a>
            <a rel="noopener noreferrer" href="https://twitter.com/instibuddyapp/" target="_blank"><MDBIcon fab icon="twitter" className="mr-3"/></a>
            <a rel="noopener noreferrer" href="https://www.instagram.com/instibuddyapp/" target="_blank"><MDBIcon fab icon="instagram" className="mr-3"/></a>
            <a rel="noopener noreferrer" href="https://www.linkedin.com/company/instibuddyapp" target="_blank"><MDBIcon fab icon="linkedin" className="mr-3"/> </a>
            </div>
            <p className="footer-copyright mb-0 py-3 text-center">
                &copy; {new Date().getFullYear()} Copyright: <a href="https://www.instibuddy.in">Instibuddy.in </a>
            </p>
        </MDBFooter>
    );
}

export default Footer;